import React, { Fragment, useEffect, useState } from "react";
import { CardBody, Col, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';

// Column Filter
const Filter = ({ column }) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ''}
        onChange={value => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  );
};

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input
      {...props}
      value={value}
      className="form-control border-0 search"
      onChange={e => setValue(e.target.value)}
    />
  );
};

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  SearchPlaceholder,
  customAddButton
}) => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({ itemRank });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  const totalPages = table.getPageCount();
  const currentPage = table.getState().pagination.pageIndex;
  const pageNumbersToShow = [];

  const startPage = Math.max(0, currentPage - 1);
  const endPage = Math.min(totalPages - 1, currentPage + 1);

  for (let i = 0; i < totalPages; i++) {
    if (i === 0 || i === 1 || (i >= startPage && i <= endPage) || i === totalPages - 1) {
      pageNumbersToShow.push(i);
    }
  }

  // Calculate the current row display range
  const currentPageSize = table.getState().pagination.pageSize;
  const totalDataCount = data.length;
  const currentRowStart = currentPage * currentPageSize + 1;
  const currentRowEnd = Math.min((currentPage + 1) * currentPageSize, totalDataCount);

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState
  } = table;

  useEffect(() => {
    if (customPageSize) setPageSize(customPageSize);
  }, [customPageSize, setPageSize]);

  return (
    <Fragment>
      {isGlobalFilter && (
        <Row>
          <CardBody className="border border-dashed border-end-0 border-start-0 py-2 px-0">
            <Row>
              <Col lg={4}>
                <div className="search-box me-2 mb-2 d-inline-block col-12">
                  <DebouncedInput
                    value={globalFilter ?? ''}
                    onChange={value => setGlobalFilter(value)}
                    placeholder={SearchPlaceholder}
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </Col>
              <Col lg={4}>
                {customAddButton}
              </Col>
            </Row>
          </CardBody>
        </Row>
      )}

      <div className="tableParent">
        <Table className={tableClass}>
          <thead className={theadClass}>
            {getHeaderGroups().map(headerGroup => (
              <tr className={trClass} key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className={thClass}
                    {...{
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() ? (
                          <span>
                            {header.column.getIsSorted() === 'asc' ? ' ↑' : ' ↓'}
                          </span>
                        ) : null}
                        {header.column.getCanFilter() && <Filter column={header.column} />}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map(row => (
                <React.Fragment key={row.id}>
                  <tr>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                  {row.original.expandedContent && (
                    <tr>
                      <td className="p-0" colSpan={columns.length}>
                        {row.original.expandedContent}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td className='text-center' colSpan={columns.length}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <div className='mt-2' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="text-muted fs-12">
          Showing <span className="fw-semibold">{currentRowEnd}</span> of <span className="fw-semibold">{totalDataCount}</span> Results
        </div>

        <Pagination size='sm'>
          <PaginationItem disabled={!table.getCanPreviousPage()}>
            <PaginationLink onClick={() => table.setPageIndex(0)}>1</PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={!table.getCanPreviousPage()}>
            <PaginationLink onClick={table.previousPage}>← Prev</PaginationLink>
          </PaginationItem>

          {pageNumbersToShow.map(page => (
            <PaginationItem key={page} active={page === currentPage}>
              <PaginationLink onClick={() => table.setPageIndex(page)}>{page + 1}</PaginationLink>
            </PaginationItem>
          ))}

          <PaginationItem disabled={!table.getCanNextPage()}>
            <PaginationLink onClick={table.nextPage}>Next →</PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={!table.getCanNextPage()}>
            <PaginationLink onClick={() => table.setPageIndex(totalPages - 1)}>{totalPages}</PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>

    </Fragment>
  );
};

export default TableContainer;