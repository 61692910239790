import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from "react-select";
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { editCompanyCEO, editCompanyContact, editCompanyMerchantBusiness, editCompanyPaymentInfo, editCompanyServiceProvider, getAllUser } from '../../apiConfig/apiCall';
import userImg from "../../assets/images/users/user-dummy-img.jpg";
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Error from '../../Components/Common/Error';
import Loader from '../../Components/Common/Loader';
import useReactSelectCustomStyle from '../../hooks/useReactSelectCustomStyle';
import useUserDetails from '../../hooks/useUserDetails';
import { getCompanyDetailsData } from '../../slices/CompanyDetails/thunk';
import ProfileSelector from "./ImageCropper/ProfileSelector";

const CompanyDetails = () => {
    // State For Handle Forms
    const [submittingForm, setSubmittingForm] = useState(false);

    const selectBoxCustomStyles = useReactSelectCustomStyle();
    const { CLIENT_ID, CURRENT_ROLE } = useUserDetails();

    const SERVER_URL = process.env.REACT_APP_API_IMAGE_URL;

    const dispatch = useDispatch();
    const { isLoading, isError, companyDetailsData, assignedCEO, contactName } = useSelector(state => state.CompanyDetails);

    useEffect(() => {
        if (CLIENT_ID) {
            dispatch(getCompanyDetailsData(CLIENT_ID));
            getUsers(clientUserRole, CLIENT_ID);
        }
    }, [CLIENT_ID, dispatch]);

    const role = CURRENT_ROLE;

    // User List For AdminCEO Options
    const clientUserRole = 'CLIENT_USER';

    const getUsers = async (clientUserRole, CLIENT_ID) => {
        try {
            const apiresponse = await getAllUser(clientUserRole, CLIENT_ID);
            if (apiresponse.status === 200) {
                setAdminCEOOptions(apiresponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Select State & Options For CEO
    const [selectedAdminCEO, setSelectedAdminCEO] = useState(null);
    const [adminCEOOptions, setAdminCEOOptions] = useState([]);

    function handleSelectAdminCEO(selectedAdminCEO) {
        setSelectedAdminCEO(selectedAdminCEO);
    }

    const AdminCEOOptions = adminCEOOptions.map((item) => ({
        value: item.ssoId,
        label: `${capitalizeFirstLetter(item.firstName)} ${capitalizeFirstLetter(item.lastName)} (${item.ssoId})`,
    }));

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    // Select State & Options For Country
    const [selectedCountry, setSelectedCountry] = useState(null);
    const CountryOptions = [
        { value: 'Thailand', label: 'Thailand' },
        { value: 'Turkmenistan', label: 'Turkmenistan' },
        { value: 'Ethiopia', label: 'Ethiopia' },
        { value: 'Singapore', label: 'Singapore' },
        { value: 'Niue', label: 'Niue' },
        { value: 'Liberia', label: 'Liberia' },
        { value: 'Jamaica', label: 'Jamaica' },
        { value: 'Norway', label: 'Norway' },
        { value: 'Bolivia', label: 'Bolivia' },
        { value: 'Bhutan', label: 'Bhutan' },
        { value: 'Bosnia & Herzegovina', label: 'Bosnia & Herzegovina' },
        { value: 'Germany', label: 'Germany' },
        { value: 'Luxembourg', label: 'Luxembourg' },
        { value: 'Chad', label: 'Chad' },
        { value: 'South Sudan', label: 'South Sudan' },
        { value: 'Mauritania', label: 'Mauritania' },
        { value: 'Uganda', label: 'Uganda' },
        { value: 'Montenegro', label: 'Montenegro' },
        { value: 'Egypt', label: 'Egypt' },
        { value: 'Georgia', label: 'Georgia' },
        { value: 'Nigeria', label: 'Nigeria' },
        { value: 'Palau', label: 'Palau' },
        { value: 'Poland', label: 'Poland' },
        { value: 'Serbia and Montenegro', label: 'Serbia and Montenegro' },
        { value: 'India', label: 'India' },
        { value: 'Cameroon', label: 'Cameroon' },
        { value: 'Western Sahara', label: 'Western Sahara' },
        { value: 'Svalbard & Jan Mayen', label: 'Svalbard & Jan Mayen' },
        { value: 'United States', label: 'United States' },
        { value: 'Macao SAR China', label: 'Macao SAR China' },
        { value: 'Iceland', label: 'Iceland' },
        { value: 'Kenya', label: 'Kenya' },
        { value: 'Niger', label: 'Niger' },
        { value: 'Armenia', label: 'Armenia' },
        { value: 'Ghana', label: 'Ghana' },
        { value: 'St. Pierre & Miquelon', label: 'St. Pierre & Miquelon' },
        { value: 'Comoros', label: 'Comoros' },
        { value: 'Turkey', label: 'Turkey' },
        { value: 'Greenland', label: 'Greenland' },
        { value: 'Dominican Republic', label: 'Dominican Republic' },
        { value: 'Nauru', label: 'Nauru' },
        { value: 'Rwanda', label: 'Rwanda' },
        { value: 'Australia', label: 'Australia' },
        { value: 'Cyprus', label: 'Cyprus' },
        { value: 'Eritrea', label: 'Eritrea' },
        { value: 'Croatia', label: 'Croatia' },
        { value: 'Congo - Kinshasa', label: 'Congo - Kinshasa' },
        { value: 'Israel', label: 'Israel' },
        { value: 'Afghanistan', label: 'Afghanistan' },
        { value: 'Syria', label: 'Syria' },
        { value: 'Russia', label: 'Russia' },
        { value: 'American Samoa', label: 'American Samoa' },
        { value: 'Vanuatu', label: 'Vanuatu' },
        { value: 'Taiwan', label: 'Taiwan' },
        { value: 'Mozambique', label: 'Mozambique' },
        { value: 'Italy', label: 'Italy' },
        { value: 'Spain', label: 'Spain' },
        { value: 'Tanzania', label: 'Tanzania' },
        { value: 'Myanmar (Burma)', label: 'Myanmar (Burma)' },
        { value: 'Jersey', label: 'Jersey' },
        { value: 'Central African Republic', label: 'Central African Republic' },
        { value: 'Denmark', label: 'Denmark' },
        { value: 'Christmas Island', label: 'Christmas Island' },
        { value: 'Austria', label: 'Austria' },
        { value: 'China', label: 'China' },
        { value: 'Puerto Rico', label: 'Puerto Rico' },
        { value: 'New Caledonia', label: 'New Caledonia' },
        { value: 'United Kingdom', label: 'United Kingdom' },
        { value: 'Switzerland', label: 'Switzerland' },
        { value: 'Pakistan', label: 'Pakistan' },
        { value: 'Bangladesh', label: 'Bangladesh' },
        { value: 'Finland', label: 'Finland' },
        { value: 'Netherlands', label: 'Netherlands' },
        { value: 'Congo - Brazzaville', label: 'Congo - Brazzaville' },
        { value: 'Nepal', label: 'Nepal' },
        { value: 'Azerbaijan', label: 'Azerbaijan' },
        { value: 'Iran', label: 'Iran' },
        { value: 'France', label: 'France' },
        { value: 'Honduras', label: 'Honduras' },
        { value: 'Hungary', label: 'Hungary' },
        { value: 'Senegal', label: 'Senegal' },
        { value: 'North Macedonia', label: 'North Macedonia' },
        { value: 'Bulgaria', label: 'Bulgaria' },
        { value: 'Somalia', label: 'Somalia' },
        { value: 'Estonia', label: 'Estonia' },
        { value: 'Oman', label: 'Oman' },
        { value: 'Latvia', label: 'Latvia' },
        { value: 'Uzbekistan', label: 'Uzbekistan' },
        { value: 'Iraq', label: 'Iraq' },
        { value: 'Brazil', label: 'Brazil' },
        { value: 'Tunisia', label: 'Tunisia' },
        { value: 'Serbia', label: 'Serbia' },
        { value: 'French Polynesia', label: 'French Polynesia' },
        { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
        { value: 'Madagascar', label: 'Madagascar' },
        { value: 'Tuvalu', label: 'Tuvalu' },
        { value: 'Pitcairn Islands', label: 'Pitcairn Islands' },
        { value: 'Hong Kong SAR China', label: 'Hong Kong SAR China' },
        { value: 'Guyana', label: 'Guyana' },
        { value: 'Curaçao', label: 'Curaçao' },
        { value: 'Algeria', label: 'Algeria' },
        { value: 'Côte d’Ivoire', label: 'Côte d’Ivoire' },
        { value: 'world', label: 'world' },
        { value: 'St. Lucia', label: 'St. Lucia' },
        { value: 'Burkina Faso', label: 'Burkina Faso' },
        { value: 'Isle of Man', label: 'Isle of Man' },
        { value: 'Mali', label: 'Mali' },
        { value: 'Trinidad & Tobago', label: 'Trinidad & Tobago' },
        { value: 'Slovenia', label: 'Slovenia' },
        { value: 'South Africa', label: 'South Africa' },
        { value: 'Belgium', label: 'Belgium' },
        { value: 'Venezuela', label: 'Venezuela' },
        { value: 'Bermuda', label: 'Bermuda' },
        { value: 'Cape Verde', label: 'Cape Verde' },
        { value: 'Vietnam', label: 'Vietnam' },
        { value: 'Mauritius', label: 'Mauritius' },
        { value: 'San Marino', label: 'San Marino' },
        { value: 'Mayotte', label: 'Mayotte' },
        { value: 'Malta', label: 'Malta' },
        { value: 'Ukraine', label: 'Ukraine' },
        { value: 'Djibouti', label: 'Djibouti' },
        { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
        { value: 'Tonga', label: 'Tonga' },
        { value: 'Malaysia', label: 'Malaysia' },
        { value: 'Burundi', label: 'Burundi' },
        { value: 'Sri Lanka', label: 'Sri Lanka' },
        { value: 'Philippines', label: 'Philippines' },
        { value: 'Tajikistan', label: 'Tajikistan' },
        { value: 'Peru', label: 'Peru' },
        { value: 'Ecuador', label: 'Ecuador' },
        { value: 'Indonesia', label: 'Indonesia' },
        { value: 'Dominica', label: 'Dominica' },
        { value: 'Ireland', label: 'Ireland' },
        { value: 'Samoa', label: 'Samoa' },
        { value: 'New Zealand', label: 'New Zealand' },
        { value: 'Zimbabwe', label: 'Zimbabwe' },
        { value: 'Ceuta & Melilla', label: 'Ceuta & Melilla' },
        { value: 'Seychelles', label: 'Seychelles' },
        { value: 'Sierra Leone', label: 'Sierra Leone' },
        { value: 'St. Helena', label: 'St. Helena' },
        { value: 'Lithuania', label: 'Lithuania' },
        { value: 'U.S. Outlying Islands', label: 'U.S. Outlying Islands' },
        { value: 'Laos', label: 'Laos' },
        { value: 'Lebanon', label: 'Lebanon' },
        { value: 'Guinea', label: 'Guinea' },
        { value: 'Belize', label: 'Belize' },
        { value: 'United Arab Emirates', label: 'United Arab Emirates' },
        { value: 'Mongolia', label: 'Mongolia' },
        { value: 'Micronesia', label: 'Micronesia' },
        { value: 'Wallis & Futuna', label: 'Wallis & Futuna' },
        { value: 'Gambia', label: 'Gambia' },
        { value: 'Sudan', label: 'Sudan' },
        { value: 'Japan', label: 'Japan' },
        { value: 'El Salvador', label: 'El Salvador' },
        { value: 'Falkland Islands', label: 'Falkland Islands' },
        { value: 'Diego Garcia', label: 'Diego Garcia' },
        { value: 'São Tomé & Príncipe', label: 'São Tomé & Príncipe' },
        { value: 'Sweden', label: 'Sweden' },
        { value: 'Latin America', label: 'Latin America' },
        { value: 'Liechtenstein', label: 'Liechtenstein' },
        { value: 'Canary Islands', label: 'Canary Islands' },
        { value: 'Solomon Islands', label: 'Solomon Islands' },
        { value: 'Morocco', label: 'Morocco' },
        { value: 'Moldova', label: 'Moldova' },
        { value: 'Uruguay', label: 'Uruguay' },
        { value: 'Fiji', label: 'Fiji' },
        { value: 'Barbados', label: 'Barbados' },
        { value: 'Kuwait', label: 'Kuwait' },
        { value: 'Lesotho', label: 'Lesotho' },
        { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
        { value: 'Jordan', label: 'Jordan' },
        { value: 'Montserrat', label: 'Montserrat' },
        { value: 'Guernsey', label: 'Guernsey' },
        { value: 'Zambia', label: 'Zambia' },
        { value: 'Saudi Arabia', label: 'Saudi Arabia' },
        { value: 'Greece', label: 'Greece' },
        { value: 'Vatican City', label: 'Vatican City' },
        { value: 'Colombia', label: 'Colombia' },
        { value: 'St. Vincent & Grenadines', label: 'St. Vincent & Grenadines' },
        { value: 'Europe', label: 'Europe' },
        { value: 'St. Kitts & Nevis', label: 'St. Kitts & Nevis' },
        { value: 'Romania', label: 'Romania' },
        { value: 'Guatemala', label: 'Guatemala' },
        { value: 'Chile', label: 'Chile' },
        { value: 'Albania', label: 'Albania' },
        { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
        { value: 'Grenada', label: 'Grenada' },
        { value: 'Botswana', label: 'Botswana' },
        { value: 'Kiribati', label: 'Kiribati' },
        { value: 'South Korea', label: 'South Korea' },
        { value: 'St. Martin', label: 'St. Martin' },
        { value: 'Eswatini', label: 'Eswatini' },
        { value: 'Faroe Islands', label: 'Faroe Islands' },
        { value: 'Kazakhstan', label: 'Kazakhstan' },
        { value: 'Panama', label: 'Panama' },
        { value: 'Palestinian Territories', label: 'Palestinian Territories' },
        { value: 'Monaco', label: 'Monaco' },
        { value: 'Yemen', label: 'Yemen' },
        { value: 'Sint Maarten', label: 'Sint Maarten' },
        { value: 'North Korea', label: 'North Korea' },
        { value: 'Suriname', label: 'Suriname' },
        { value: 'Bahamas', label: 'Bahamas' },
        { value: 'Caribbean Netherlands', label: 'Caribbean Netherlands' },
        { value: 'St. Barthélemy', label: 'St. Barthélemy' },
        { value: 'Mexico', label: 'Mexico' },
        { value: 'Namibia', label: 'Namibia' },
        { value: 'Marshall Islands', label: 'Marshall Islands' },
        { value: 'Benin', label: 'Benin' },
        { value: 'Slovakia', label: 'Slovakia' },
        { value: 'Haiti', label: 'Haiti' },
        { value: 'Réunion', label: 'Réunion' },
        { value: 'Guadeloupe', label: 'Guadeloupe' },
        { value: 'Norfolk Island', label: 'Norfolk Island' },
        { value: 'Kosovo', label: 'Kosovo' },
        { value: 'Guam', label: 'Guam' },
        { value: 'Aruba', label: 'Aruba' },
        { value: 'Anguilla', label: 'Anguilla' },
        { value: 'Czechia', label: 'Czechia' },
        { value: 'Papua New Guinea', label: 'Papua New Guinea' },
        { value: 'Timor-Leste', label: 'Timor-Leste' },
        { value: 'Paraguay', label: 'Paraguay' },
        { value: 'Belarus', label: 'Belarus' },
        { value: 'Togo', label: 'Togo' },
        { value: 'Cook Islands', label: 'Cook Islands' },
        { value: 'Antigua & Barbuda', label: 'Antigua & Barbuda' },
        { value: 'Andorra', label: 'Andorra' },
        { value: 'Canada', label: 'Canada' },
        { value: 'Cayman Islands', label: 'Cayman Islands' },
        { value: 'Tokelau', label: 'Tokelau' },
        { value: 'Angola', label: 'Angola' },
        { value: 'Cambodia', label: 'Cambodia' },
        { value: 'Bahrain', label: 'Bahrain' },
        { value: 'Martinique', label: 'Martinique' },
        { value: 'Argentina', label: 'Argentina' },
        { value: 'French Guiana', label: 'French Guiana' },
        { value: 'Malawi', label: 'Malawi' },
        { value: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory' },
        { value: 'Qatar', label: 'Qatar' },
        { value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
        { value: 'Portugal', label: 'Portugal' },
        { value: 'Cuba', label: 'Cuba' },
        { value: 'U.S. Virgin Islands', label: 'U.S. Virgin Islands' },
        { value: 'Costa Rica', label: 'Costa Rica' },
        { value: 'Gabon', label: 'Gabon' },
        { value: 'Libya', label: 'Libya' },
        { value: 'Gibraltar', label: 'Gibraltar' },
        { value: 'British Virgin Islands', label: 'British Virgin Islands' },
        { value: 'Turks & Caicos Islands', label: 'Turks & Caicos Islands' },
        { value: 'Åland Islands', label: 'Åland Islands' },
        { value: 'Nicaragua', label: 'Nicaragua' },
        { value: 'Brunei', label: 'Brunei' }
    ];
    function handleSelectCountry(selectedCountry) {
        setSelectedCountry(selectedCountry);
    }

    // State & Handle Change For Edit Company Details
    const [inputValues, setInputValues] = useState({ address: '', contactNumber: '', email: '', city: '', state: '', adminCEO: '', country: '', zipCode: '', companyUrl: '', paymentApplicationInUse: '', paymentApplicationVersion: '', retailer: '', eCommerce: '', telecommunication: '', mailTelephoneOrde: '', grocerySupermark: '', travelEntertainment: '', petroleum: '', otherMerchantBusiness: '', authorization: '', loyaltyPrograms: '', switching: '', d3SecureAccessControlServer: '', ipspECommerce: '', processMagneticStripeTransactions: '', paymentGateway: '', clearingSettlement: '', hosting: '', processMOTOTransactions: '', issuingProcessing: '', otherserviceProviderBusiness: '', file: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'address') {
            const specialCharPattern = /[^a-zA-Z0-9\s]/;

            if (value.length > 50) {
                toast.error("Address cannot exceed 50 characters.");
                return;
            }

            if (specialCharPattern.test(value)) {
                toast.error("Address cannot contain special characters.");
                return;
            }
        }

        else if (name === 'contactNumber') {
            if (!/^\d*$/.test(value)) {
                toast.error("Contact Number can only contain digits.");
                return;
            }
            if (value.length > 15) {
                toast.error("Contact Number cannot exceed 15 digits.");
                return;
            }
        }
        else if (name === 'email' && value.length > 50) {
            toast.error("Email ID cannot exceed 50 characters.");
            return;
        }
        else if (name === 'city' && value.length > 20) {
            toast.error("City cannot exceed 20 characters.");
            return;
        }
        else if (name === 'state' && value.length > 20) {
            toast.error("State cannot exceed 20 characters.");
            return;
        }
        else if (name === 'zipCode' && value.length > 8) {
            toast.error("Zip Code cannot exceed 8 digits.");
            return;
        }
        else if (name === 'zipCode') {
            if (!/^\d*$/.test(value)) {
                toast.error("Zip Code can only contain digits.");
                return;
            }
            if (value.length > 15) {
                toast.error("Zip Code cannot exceed 8 digits.");
                return;
            }
        }
        else if (name === 'companyUrl' && value.length > 100) {
            toast.error("Company Url cannot exceed 100 characters.");
            return;
        }
        setInputValues({ ...inputValues, [name]: value });
    };


    // Company Info Modal
    const [companyInfoModal, setCompanyInfoModal] = useState(false);
    // Assigned CEO Modal
    const [assignedCEOModal, setAssignedCEOModal] = useState(false);
    // Payment Info Modal
    const [paymentInfoModal, setPaymentInfoModal] = useState(false);
    // Merchant Bussiness Modal
    const [merchantBusinessModal, setMerchantBusinessModal] = useState(false);
    // Service Bussiness Provider Modal
    const [servicesProviderModal, setServicesProviderModal] = useState(false);


    // Handle Modal & Data For Company Info
    const companyInfoModalToggle = () => setCompanyInfoModal(!companyInfoModal);
    const handleEditCompanyInfo = () => {
        try {
            setCompanyInfoModal(true);
            setSelectedCountry({
                label: companyDetailsData.country || '',
                value: companyDetailsData.country || ''
            });
            setInputValues({
                address: companyDetailsData.address || '',
                contactNumber: companyDetailsData.contactNumber || '',
                email: companyDetailsData.email || '',
                city: companyDetailsData.city || '',
                state: companyDetailsData.state || '',
                zipCode: companyDetailsData.zipCode || '',
                companyUrl: companyDetailsData.companyUrl || '',
            });
        } catch (error) {
            console.log(error);
        }
    }

    // Handle Modal & Data For Assigned CEO 
    const assignedCEOModalToggle = () => setAssignedCEOModal(!assignedCEOModal);
    const handleEditAssignedCEO = () => {
        try {
            setAssignedCEOModal(true);
            setSelectedAdminCEO({
                label: assignedCEO.firstName + " " + assignedCEO.lastName + " " + "(" + assignedCEO.ssoId + ")" || '',
                value: assignedCEO.firstName + " " + assignedCEO.lastName + " " + "(" + assignedCEO.ssoId + ")" || '',
            });
        } catch (error) {
            console.log(error);
        }
    }

    // Handle Modal & Data For Payment Info
    const paymentInfoModalToggle = () => setPaymentInfoModal(!paymentInfoModal);
    const handleEditPaymentInfo = () => {
        try {
            setPaymentInfoModal(true);

            setInputValues({

                paymentApplicationInUse: companyDetailsData.paymentApplicationInUse || '',
                paymentApplicationVersion: companyDetailsData.paymentApplicationVersion || '',
            });
        } catch (error) {
            console.log(error);
        }
    }

    // Handle Modal & Data For Merchant Bussiness
    const merchantBusinessModalToggle = () => setMerchantBusinessModal(!merchantBusinessModal);
    const [selectedMerchantBusiness, setSelectedMerchantBusiness] = useState([]);
    const merchantBusinessCategories = [
        'Retailer',
        'E-Commerce',
        'Telecommunication',
        'Mail-Telephone Order',
        'Grocery & Supermarket',
        'Travel & Entertainment',
        'Petroleum'
    ];
    const handleMerchantBusinessCheckboxChange = (category) => {
        if (selectedMerchantBusiness.includes(category)) {
            setSelectedMerchantBusiness(selectedMerchantBusiness.filter(item => item !== category));
        } else {
            setSelectedMerchantBusiness([...selectedMerchantBusiness, category]);
        }
    };
    const handleEditMerchantBusiness = () => {
        try {
            setInputValues({
                otherMerchantBusiness: companyDetailsData.otherMerchantBusiness || '',
            });

            const preselectedCategories = [];
            if (companyDetailsData.retailer) preselectedCategories.push('Retailer');
            if (companyDetailsData.eCommerce) preselectedCategories.push('E-Commerce');
            if (companyDetailsData.telecommunication) preselectedCategories.push('Telecommunication');
            if (companyDetailsData.mailTelephoneOrder) preselectedCategories.push('Mail-Telephone Order');
            if (companyDetailsData.grocerySupermarket) preselectedCategories.push('Grocery & Supermarket');
            if (companyDetailsData.travelEntertainment) preselectedCategories.push('Travel & Entertainment');
            if (companyDetailsData.petroleum) preselectedCategories.push('Petroleum');

            setSelectedMerchantBusiness(preselectedCategories);
            setMerchantBusinessModal(true);
        } catch (error) {
            console.log(error);
        }
    };

    // Handle Modal & Data For Service Provider Bussiness
    const servicesProviderModalToggle = () => setServicesProviderModal(!servicesProviderModal);
    const [selectedServicesProvider, setSelectedServicesProvider] = useState([]);
    const servicesProviderCategories = [
        'Authorization',
        'Loyalty Programs',
        'Switching',
        '3D Secure Access Control Server',
        'IPSP (E-Commerce)',
        'Process Magnetic Stripe Transactions',
        'Payment Gateway',
        'Clearing & Settlement',
        'Hosting',
        'Process MO/TO Transactions',
        'Issuing / Processing',
    ];
    const handleServicesProviderCheckboxChange = (category) => {
        if (selectedServicesProvider.includes(category)) {
            setSelectedServicesProvider(selectedServicesProvider.filter(item => item !== category));
        } else {
            setSelectedServicesProvider([...selectedServicesProvider, category]);
        }
    };
    const handleEditServicesProvider = () => {
        try {
            setInputValues({
                otherserviceProviderBusiness: companyDetailsData.otherserviceProviderBusiness || '',
            });
            const preselectedCategories = [];
            if (companyDetailsData.authorization) preselectedCategories.push('Authorization');
            if (companyDetailsData.loyaltyPrograms) preselectedCategories.push('Loyalty Programs');
            if (companyDetailsData.switching) preselectedCategories.push('Switching');
            if (companyDetailsData.d3SecureAccessControlServer) preselectedCategories.push('3D Secure Access Control Server');
            if (companyDetailsData.ipspECommerce) preselectedCategories.push('IPSP (E-Commerce)');
            if (companyDetailsData.processMagneticStripeTransactions) preselectedCategories.push('Process Magnetic Stripe Transactions');
            if (companyDetailsData.paymentGateway) preselectedCategories.push('Payment Gateway');
            if (companyDetailsData.clearingSettlement) preselectedCategories.push('Clearing & Settlement');
            if (companyDetailsData.hosting) preselectedCategories.push('Hosting');
            if (companyDetailsData.processMOTOTransactions) preselectedCategories.push('Process MO/TO Transactions');
            if (companyDetailsData.issuingProcessing) preselectedCategories.push('Issuing / Processing');
            setSelectedServicesProvider(preselectedCategories);
            setServicesProviderModal(true);
        } catch (error) {
            console.log(error);
        }
    };

    // Handle Reset & Edit Company Info
    const handleSubmitEditCompanyInfo = async (id, e) => {

        e.preventDefault();

        const { address, contactNumber, email, city, state, zipCode, companyUrl } = inputValues;

        if (!address || !contactNumber || !email || !city || !state || !zipCode || !companyUrl) {
            toast.error("All fields are required.");
            return;
        }

        if (address && address.length > 50) {
            toast.error("Address cannot exceed 50 characters.");
            return;
        }

        if (contactNumber && !/^\d{8,15}$/.test(contactNumber)) {
            toast.error("Contact number must be between 8 and 15 digits.");
            return;
        }

        if (email) {
            // Validate the basic email structure
            if (!/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/.test(email)) {
                toast.error("Please enter a valid email address.");
                return;
            }

            if (email.includes("@gmail.com") && !/^[^\s@]+@gmail\.com$/.test(email)) {
                toast.error("Please enter a valid Gmail address.");
                return;
            }

            if (!email.includes("@gmail.com") && email.split('.').length > 2) {
                toast.error("Please enter a valid email addres.");
                return;
            }
        }


        if (city && city.length > 20) {
            toast.error("City cannot exceed 20 characters.");
            return;
        }

        if (state && state.length > 20) {
            toast.error("State cannot exceed 20 characters.");
            return;
        }

        if (zipCode && !/^\d{1,8}$/.test(zipCode)) {
            toast.error("Zip code must be a valid number and up to 8 digits.");
            return;
        }

        if (!/^(https?:\/\/)([\w-]+(\.[\w-]+)+)(\/[\w-]*)*$/i.test(companyUrl)) {
            toast.error('Please enter a valid URL starting with http:// or https://');
            return;
        }

        try {
            const data = {
                address: address === undefined ? companyDetailsData.address : address,
                contactNumber: contactNumber === undefined ? companyDetailsData.contactNumber : contactNumber,
                email: email === undefined ? companyDetailsData.email : email,
                city: city === undefined ? companyDetailsData.city : city,
                state: state === undefined ? companyDetailsData.state : state,
                zipCode: zipCode === undefined ? companyDetailsData.zipCode : zipCode,
                companyUrl: companyUrl === undefined ? companyDetailsData.companyUrl : companyUrl,
                country: selectedCountry.value,
            };
            setSubmittingForm(true);
            const response = await editCompanyContact(id, data);
            if (response.status === 200) {
                toast.success(response.data);
                setCompanyInfoModal(false);
                dispatch(getCompanyDetailsData(CLIENT_ID));
            } else {
                toast.error(response.data);
            }
        } catch (error) {
            console.error('Error sending data:', error);
            toast.error('An error occurred while update the company info');
        } finally {
            setSubmittingForm(false);
        }
    };
    const handleResetFormCompanyInfo = async () => {
        setInputValues({
            address: "",
            contactNumber: "",
            email: "",
            city: "",
            state: "",
            zipCode: "",
            companyUrl: "",
        });

        setSelectedCountry({
            value: "",
            label: "Select Country",
        });
    };

    // Handle Reset & Edit Company CEO
    const handleSubmitEditCompanyCEO = async (id, e) => {
        e.preventDefault();
        if (!selectedAdminCEO || !selectedAdminCEO.value) {
            toast.error("Please select a CEO.");
            return;
        }
        try {
            const data = {
                adminCeo: selectedAdminCEO.value,
            };
            setSubmittingForm(true);
            const response = await editCompanyCEO(id, data);

            if (response.status === 200) {
                toast.success(response.data);
                setAssignedCEOModal(false);
                dispatch(getCompanyDetailsData(CLIENT_ID));
            } else {
                toast.error(response.data);
            }
        } catch (error) {
            console.log(error.message);
            toast.error("An error occurred while updating the CEO.");
        } finally {
            setSubmittingForm(false);
        }
    };
    const handleResetFormCompanyCEO = async () => {
        setSelectedAdminCEO({
            value: "",
            label: "Select CEO",
        });
    }

    // Handle Reset & Edit Payment Info
    const handleSubmitEditCompanyPaymentInfo = async (id, e) => {
        try {
            e.preventDefault();
            const { paymentApplicationInUse, paymentApplicationVersion } = inputValues;

            if (!paymentApplicationInUse || paymentApplicationInUse.trim() === "") {
                toast.error("Payment Application In Use is required.");
                return;
            } else if (paymentApplicationInUse.length > 50) {
                toast.error("Payment Application In Use must be less than or equal to 50 characters.");
                return;
            }

            if (!paymentApplicationVersion || paymentApplicationVersion.trim() === "") {
                toast.error("Payment Application Version is required.");
                return;
            } else if (paymentApplicationVersion.length > 50) {
                toast.error("Payment Application Version must be less than or equal to 50 characters.");
                return;
            }

            const data = {
                paymentApplicationInUse,
                paymentApplicationVersion
            };

            setSubmittingForm(true);
            // Proceed with the API call
            const response = await editCompanyPaymentInfo(id, data);

            if (response.status === 200) {
                toast.success(response.data);
                setPaymentInfoModal(false);
                dispatch(getCompanyDetailsData(CLIENT_ID));
            } else {
                toast.error(response.data);
            }
        } catch (error) {
            console.log(error.message);
            toast.error("An error occurred while updating the payment info.");
        } finally {
            setSubmittingForm(false);
        }
    };
    const handleResetCompanyPaymentInfo = async () => {
        setInputValues({ paymentApplicationInUse: "", paymentApplicationVersion: "" })
    }

    // Handle Edit Company Merchant Business
    const handleSubmitEditCompanyMerchantBusiness = async (id, e) => {
        try {
            e.preventDefault();
            const { otherMerchantBusiness } = inputValues;
            const updatedMerchantBusiness = {};
            merchantBusinessCategories.forEach(category => {
                const formattedCategory = category.replace(/-/g, '_').replace(/ & /g, '_').replace(/ /g, '_');
                updatedMerchantBusiness[formattedCategory] = selectedMerchantBusiness.includes(category);
            });

            const data = selectedMerchantBusiness.length === 0
                ? {
                    retailer: false,
                    eCommerce: false,
                    telecommunication: false,
                    mailTelephoneOrder: false,
                    grocerySupermarket: false,
                    travelEntertainment: false,
                    petroleum: false,
                    otherMerchantBusiness: otherMerchantBusiness === undefined
                        ? companyDetailsData.otherMerchantBusiness
                        : otherMerchantBusiness,
                }
                : {
                    retailer: updatedMerchantBusiness.Retailer,
                    eCommerce: updatedMerchantBusiness.E_Commerce,
                    telecommunication: updatedMerchantBusiness.Telecommunication,
                    mailTelephoneOrder: updatedMerchantBusiness.Mail_Telephone_Order,
                    grocerySupermarket: updatedMerchantBusiness.Grocery_Supermarket,
                    travelEntertainment: updatedMerchantBusiness.Travel_Entertainment,
                    petroleum: updatedMerchantBusiness.Petroleum,
                    otherMerchantBusiness: otherMerchantBusiness === undefined
                        ? companyDetailsData.otherMerchantBusiness
                        : otherMerchantBusiness,
                };

            setSubmittingForm(true);
            const response = await editCompanyMerchantBusiness(id, data);
            if (response.status === 200) {
                toast.success(response.data);
                setMerchantBusinessModal(false);
                dispatch(getCompanyDetailsData(CLIENT_ID));
            } else {
                toast.error(response.data);
            }
        } catch (error) {
            console.log(error.message);
            toast.error("An error occurred while updating the merchant business.");
        } finally {
            setSubmittingForm(false);
        }
    }

    // Handle Edit Company Service Provider
    const handleSubmitEditCompanyServiceProvider = async (id, e) => {
        try {
            e.preventDefault();
            const { otherserviceProviderBusiness } = inputValues;
            const updatedServicesProvider = {};
            servicesProviderCategories.forEach(category => {
                const formattedCategory = category.replace(/3D/g, 'D3').replace(/-/g, '_').replace(/ & /g, '_').replace(/ /g, '_').replace(/\//g, '_').replace(/[()]/g, '');
                updatedServicesProvider[formattedCategory] = selectedServicesProvider.includes(category);
            });

            const data = selectedServicesProvider.length === 0
                ? {
                    authorization: false,
                    switching: false,
                    ipspECommerce: false,
                    paymentGateway: false,
                    hosting: false,
                    issuingProcessing: false,
                    loyaltyPrograms: false,
                    d3SecureAccessControlServer: false,
                    processMagneticStripeTransactions: false,
                    clearingSettlement: false,
                    processMOTOTransactions: false,
                    otherserviceProviderBusiness: otherserviceProviderBusiness === undefined
                        ? companyDetailsData.otherserviceProviderBusiness
                        : otherserviceProviderBusiness
                }
                : {
                    authorization: updatedServicesProvider.Authorization,
                    switching: updatedServicesProvider.Switching,
                    ipspECommerce: updatedServicesProvider.IPSP_E_Commerce,
                    paymentGateway: updatedServicesProvider.Payment_Gateway,
                    hosting: updatedServicesProvider.Hosting,
                    issuingProcessing: updatedServicesProvider.Issuing___Processing,
                    loyaltyPrograms: updatedServicesProvider.Loyalty_Programs,
                    d3SecureAccessControlServer: updatedServicesProvider.D3_Secure_Access_Control_Server,
                    processMagneticStripeTransactions: updatedServicesProvider.Process_Magnetic_Stripe_Transactions,
                    clearingSettlement: updatedServicesProvider.Clearing_Settlement,
                    processMOTOTransactions: updatedServicesProvider.Process_MO_TO_Transactions,
                    otherserviceProviderBusiness: otherserviceProviderBusiness === undefined
                        ? companyDetailsData.otherserviceProviderBusiness
                        : otherserviceProviderBusiness
                };
            setSubmittingForm(true);
            const response = await editCompanyServiceProvider(id, data);
            if (response.status === 200) {
                toast.success(response.data);
                setServicesProviderModal(false);
                dispatch(getCompanyDetailsData(CLIENT_ID));
            } else {
                toast.error(response.data);
            }
        } catch (error) {
            console.log(error.message);
            toast.error("An error occurred while updating the service provider.");
        } finally {
            setSubmittingForm(false);
        }
    }

    // Set Profile Image Logic
    const [editCompanyLogoModal, setEditCompanyLogoModal] = useState(false);
    const toggleEditCompanyLogoModal = () => {
        setEditCompanyLogoModal(!editCompanyLogoModal);
        setInputValues({
            file: companyDetailsData.file || '',
        });
    }

    if (isLoading) return <Loader />
    if (isError) return <Error />

    return (
        <>
            <div className="page-content company-details-page">
                <Container fluid>
                    <BreadCrumb title={'Company Details'} pageTitle={"Dashboard"} />
                    <Row>

                        <Col lg={8} className='pe-1'>
                            <Row>
                                <Col lg={6} className='pe-1 ps-1'>
                                    <Card className="mb-3 card-animate" style={{ borderBottom: '2px solid #015fbb' }}>
                                        <CardHeader className='fw-medium fs-5 p-2 d-flex justify-content-between align-items-center'>
                                            <div>Company Info</div>
                                            {
                                                role === "CLIENT_ADMIN" && (
                                                    <Button
                                                        onClick={handleEditCompanyInfo}
                                                        color="link"
                                                        style={{
                                                            background: '#fff',
                                                            color: '#000',
                                                            padding: '.5rem',
                                                            height: '1.5rem',
                                                            width: '1.5rem',
                                                            fontSize: '1.3rem',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                        <i className="ri-edit-fill"></i>
                                                    </Button>
                                                )
                                            }

                                        </CardHeader>
                                        <CardBody>
                                            <div className="table-card">
                                                <table className="table mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td className="fw-medium">Company Name</td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className='profile-user position-relative d-inline-block'>
                                                                        {
                                                                            companyDetailsData.file === "" ?
                                                                                <div className="avatar-md mt-3">
                                                                                    <div className="avatar-title rounded bg-info-subtle text-info fs-16 material-shadow">
                                                                                        CL
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <img src={`${SERVER_URL}${companyDetailsData.file}?t=${new Date().getTime()}`}
                                                                                    className="rounded-circle avatar-md img-thumbnail user-profile-image material-shadow"
                                                                                    alt="user-profile" />
                                                                        }
                                                                        {
                                                                            role === "CLIENT_ADMIN" && (

                                                                                <div className="avatar-md p-0 rounded-circle profile-photo-edit" onClick={toggleEditCompanyLogoModal}>
                                                                                    <Label htmlFor="profile-img-file-input"
                                                                                        className="profile-photo-edit" style={{ height: '1.5rem', width: '1.5rem' }}>
                                                                                        <span className="avatar-title rounded-circle bg-light text-body material-shadow">
                                                                                            <i className="ri-camera-fill"></i>
                                                                                        </span>
                                                                                    </Label>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="ms-2">{companyDetailsData.companyName}</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Address</td>
                                                            <td>{companyDetailsData.address}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Contact No</td>
                                                            <td>{companyDetailsData.contactNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Email</td>
                                                            <td><span className="text-danger fs-12">{companyDetailsData.email}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">City</td>
                                                            <td>{companyDetailsData.city}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">State</td>
                                                            <td>{companyDetailsData.state}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Country</td>
                                                            <td>{companyDetailsData.country}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Zip Code</td>
                                                            <td>{companyDetailsData.zipCode}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Company URL</td>
                                                            <td><Link target='_blank' to={`${companyDetailsData.companyUrl}`}>{companyDetailsData.companyUrl}</Link></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={6} className='ps-1'>
                                    <Card className="mb-3 card-animate" style={{ borderBottom: '2px solid #015fbb' }}>
                                        <CardHeader className='fw-medium fs-5 p-2 d-flex justify-content-between align-items-center'>
                                            <div>Merchant Business </div>
                                            {
                                                role === "CLIENT_ADMIN" && (
                                                    <Button
                                                        onClick={handleEditMerchantBusiness}
                                                        color="link"
                                                        style={{
                                                            background: '#fff',
                                                            color: '#000',
                                                            padding: '.5rem',
                                                            height: '1.5rem',
                                                            width: '1.5rem',
                                                            fontSize: '1.3rem',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                        <i className="ri-edit-fill"></i>
                                                    </Button>
                                                )
                                            }
                                        </CardHeader>
                                        <CardBody>
                                            <div className="text-dark">
                                                <ul className="list-unstyled vstack gap-2 mb-0">
                                                    <li>
                                                        <div className="form-check ps-0 d-flex align-items-center">
                                                            {
                                                                companyDetailsData.retailer === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                            }
                                                            <Label className="form-check-label">
                                                                Retailer
                                                            </Label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-check ps-0 d-flex align-items-center">
                                                            {
                                                                companyDetailsData.eCommerce === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                            }
                                                            <Label className="form-check-label">
                                                                E-Commerce
                                                            </Label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-check ps-0 d-flex align-items-center">
                                                            {
                                                                companyDetailsData.telecommunication === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                            }
                                                            <Label className="form-check-label">
                                                                Telecommunication
                                                            </Label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-check ps-0 d-flex align-items-center">
                                                            {
                                                                companyDetailsData.mailTelephoneOrder === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                            }
                                                            <Label className="form-check-label">
                                                                Mail-Telephone Order
                                                            </Label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-check ps-0 d-flex align-items-center">
                                                            {
                                                                companyDetailsData.grocerySupermarket === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                            }
                                                            <Label className="form-check-label">
                                                                Grocery & Supermarket
                                                            </Label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-check ps-0 d-flex align-items-center">
                                                            {
                                                                companyDetailsData.travelEntertainment === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                            }
                                                            <Label className="form-check-label">
                                                                Travel & Entertainment
                                                            </Label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-check ps-0 d-flex align-items-center">
                                                            {
                                                                companyDetailsData.petroleum === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                            }
                                                            <Label className="form-check-label">
                                                                Petroleum
                                                            </Label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <h6 className="mb-1 fw-semibold text-uppercase">Other</h6>
                                                        <p className='mb-0'>{companyDetailsData.otherMerchantBusiness}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={12} className=''>
                                    <Row>
                                        <Col lg={6} className='pe-1 ps-1'>
                                            <Card className="mb-3 card-animate" style={{ borderBottom: '2px solid #015fbb' }}>
                                                <CardHeader className='fw-medium fs-5 p-2 d-flex justify-content-between align-items-center'>
                                                    <div>Assigned CEO</div>
                                                    {
                                                        role === "CLIENT_ADMIN" && (
                                                            <Button
                                                                onClick={handleEditAssignedCEO}
                                                                color="link"
                                                                style={{
                                                                    background: '#fff',
                                                                    color: '#000',
                                                                    padding: '.5rem',
                                                                    height: '1.5rem',
                                                                    width: '1.5rem',
                                                                    fontSize: '1.3rem',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center'
                                                                }}>
                                                                <i className="ri-edit-fill"></i>
                                                            </Button>
                                                        )
                                                    }
                                                </CardHeader>
                                                <CardBody>
                                                    <ul className="list-unstyled vstack gap-3 mb-0">
                                                        <li>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0">
                                                                    {
                                                                        assignedCEO === undefined ?
                                                                            null
                                                                            :
                                                                            <img
                                                                                src={SERVER_URL + assignedCEO.profileImage}
                                                                                onError={(e) => { e.target.src = userImg }}
                                                                                alt="..."
                                                                                className="avatar-xs rounded-circle material-shadow" />
                                                                    }
                                                                </div>
                                                                <div className="flex-grow-1 ms-2">
                                                                    {
                                                                        assignedCEO === undefined ?
                                                                            null
                                                                            :
                                                                            <>
                                                                                <h6 className="mb-1"><Link to="/pages-profile">{assignedCEO.firstName} {assignedCEO.lastName
                                                                                }</Link></h6>
                                                                                <p className="text-muted mb-0">{assignedCEO.designation}</p>
                                                                            </>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={6} className='ps-1'>
                                            <Card className="mb-3 card-animate" style={{ borderBottom: '2px solid #015fbb' }}>
                                                <CardHeader className='fw-medium fs-5 p-2 d-flex justify-content-between align-items-center'>
                                                    <div>Contact Name</div>
                                                </CardHeader>
                                                <CardBody>
                                                    <ul className="list-unstyled vstack gap-3 mb-0">
                                                        <li>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0">
                                                                    <img
                                                                        src={SERVER_URL + contactName.profileImage}
                                                                        alt="..."
                                                                        onError={(e) => { e.target.src = userImg }}
                                                                        className="avatar-xs rounded-circle material-shadow" />
                                                                </div>
                                                                <div className="flex-grow-1 ms-2">
                                                                    <h6 className="mb-1"><Link to="/pages-profile">{contactName.firstName} {contactName.lastName}</Link></h6>
                                                                    <p className="text-muted mb-0">{contactName.designation}</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={6} className='ps-1'>
                                            <Card className="mb-3 card-animate" style={{ borderBottom: '2px solid #015fbb' }}>
                                                <CardHeader className='fw-medium fs-5 p-2 d-flex justify-content-between align-items-center'>
                                                    <div>Payment Info</div>
                                                    {
                                                        role === "CLIENT_ADMIN" && (
                                                            <Button
                                                                onClick={handleEditPaymentInfo}
                                                                color="link"
                                                                style={{
                                                                    background: '#fff',
                                                                    color: '#000',
                                                                    padding: '.5rem',
                                                                    height: '1.5rem',
                                                                    width: '1.5rem',
                                                                    fontSize: '1.3rem',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center'
                                                                }}>
                                                                <i className="ri-edit-fill"></i>
                                                            </Button>
                                                        )
                                                    }
                                                </CardHeader>
                                                <CardBody>
                                                    <div className="table-card">
                                                        <table className="table mb-0">
                                                            <tbody>

                                                                <tr>
                                                                    <td className="fw-medium">Payment Application In Use</td>
                                                                    <td>
                                                                        <span
                                                                            className={`badge fs-11 ${companyDetailsData.paymentApplicationInUse === "Yes"
                                                                                ? "bg-success-subtle text-success"
                                                                                : "bg-danger-subtle text-danger"
                                                                                }`}
                                                                        >
                                                                            {companyDetailsData.paymentApplicationInUse}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-medium">Payment Application Version</td>
                                                                    <td>
                                                                        <span
                                                                            className={`badge fs-11 ${companyDetailsData.paymentApplicationVersion === "Yes"
                                                                                ? "bg-success-subtle text-success"
                                                                                : "bg-danger-subtle text-danger"
                                                                                }`}
                                                                        >
                                                                            {companyDetailsData.paymentApplicationVersion}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={4} className='ps-1'>
                            <Card className='mb-0 card-animate' style={{ borderBottom: '2px solid #015fbb' }}>
                                <CardHeader className='fw-medium fs-5 p-2 d-flex justify-content-between align-items-center'>
                                    <div>Services Provider Business </div>
                                    {
                                        role === "CLIENT_ADMIN" && (
                                            <Button
                                                onClick={handleEditServicesProvider}
                                                color="link"
                                                style={{
                                                    background: '#fff',
                                                    color: '#000',
                                                    padding: '.5rem',
                                                    height: '1.5rem',
                                                    width: '1.5rem',
                                                    fontSize: '1.3rem',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                <i className="ri-edit-fill"></i>
                                            </Button>
                                        )
                                    }
                                </CardHeader>
                                <CardBody>
                                    <div className="text-dark">
                                        <ul className="list-unstyled vstack gap-2 mb-0">
                                            <li>
                                                <div className="form-check ps-0 d-flex align-items-center">
                                                    {
                                                        companyDetailsData.authorization === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                    }
                                                    <Label className="form-check-label">
                                                        Authorization
                                                    </Label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check ps-0 d-flex align-items-center">
                                                    {
                                                        companyDetailsData.loyaltyPrograms === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                    }
                                                    <Label className="form-check-label">
                                                        Loyalty Programs
                                                    </Label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check ps-0 d-flex align-items-center">
                                                    {
                                                        companyDetailsData.switching === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                    }
                                                    <Label className="form-check-label">
                                                        Switching
                                                    </Label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check ps-0 d-flex align-items-center">
                                                    {
                                                        companyDetailsData.d3SecureAccessControlServer === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                    }
                                                    <Label className="form-check-label">
                                                        3D Secure Access Control Server
                                                    </Label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check ps-0 d-flex align-items-center">
                                                    {
                                                        companyDetailsData.ipspECommerce === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                    }
                                                    <Label className="form-check-label">
                                                        IPSP (E-Commerce)
                                                    </Label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check ps-0 d-flex align-items-center">
                                                    {
                                                        companyDetailsData.processMagneticStripeTransactions === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                    }
                                                    <Label className="form-check-label">
                                                        Process Magnetic Stripe Transactions
                                                    </Label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check ps-0 d-flex align-items-center">
                                                    {
                                                        companyDetailsData.paymentGateway === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                    }
                                                    <Label className="form-check-label">
                                                        Payment Gateway
                                                    </Label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check ps-0 d-flex align-items-center">
                                                    {
                                                        companyDetailsData.clearingSettlement === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                    }
                                                    <Label className="form-check-label">
                                                        Clearing & Settlement
                                                    </Label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check ps-0 d-flex align-items-center">
                                                    {
                                                        companyDetailsData.hosting === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                    }
                                                    <Label className="form-check-label">
                                                        Hosting
                                                    </Label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check ps-0 d-flex align-items-center">
                                                    {
                                                        companyDetailsData.processMOTOTransactions === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                    }
                                                    <Label className="form-check-label">
                                                        Process MO/TO Transactions
                                                    </Label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check ps-0 d-flex align-items-center">
                                                    {
                                                        companyDetailsData.issuingProcessing === true ? <i className="ri-check-double-line fs-4 me-2"></i> : <i className="ri-checkbox-blank-line fs-4 me-2"></i>
                                                    }
                                                    <Label className="form-check-label">
                                                        Issuing / Processing
                                                    </Label>
                                                </div>
                                            </li>
                                            <li>
                                                <h6 className="mb-1 fw-semibold text-uppercase">Other</h6>
                                                <p className='mb-0'>{companyDetailsData.otherserviceProviderBusiness}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal isOpen={companyInfoModal} toggle={companyInfoModalToggle}>
                    <ModalHeader toggle={companyInfoModalToggle}>Edit Company Info</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row className='g-3'>

                                <Col lg={6}>
                                    <div>
                                        <Label htmlFor="address" className="form-label">Address</Label>
                                        <Input type="text" className="form-control" id="address" name='address' value={inputValues.address} onChange={(e) => handleChange(e)} placeholder='Enter address' />
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div>
                                        <Label htmlFor="contactNumber" className="form-label">Contact No</Label>
                                        <Input type="text" className="form-control" id="contactNumber" name='contactNumber' value={inputValues.contactNumber} onChange={(e) => handleChange(e)} placeholder='Enter contact no' />
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div>
                                        <Label htmlFor="email" className="form-label">Email</Label>
                                        <Input type="text" className="form-control" id="email" name='email' value={inputValues.email} onChange={(e) => handleChange(e)} placeholder='Enter email' />
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div>
                                        <Label htmlFor="city" className="form-label">City</Label>
                                        <Input type="text" className="form-control" id="city" name='city' value={inputValues.city} onChange={(e) => handleChange(e)} placeholder='Enter city' />
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div>
                                        <Label htmlFor="state" className="form-label">State</Label>
                                        <Input type="text" className="form-control" id="state" name='state' value={inputValues.state} onChange={(e) => handleChange(e)} placeholder='Enter state' />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div>
                                        <Label htmlFor="sampleInput" className="form-label">Country</Label>
                                        <Select value={selectedCountry} onChange={(option) => { handleSelectCountry(option); }} options={CountryOptions} styles={selectBoxCustomStyles} />
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div>
                                        <Label htmlFor="zipCode" className="form-label">Zip Code</Label>
                                        <Input type="text" className="form-control" id="zipCode" name='zipCode' value={inputValues.zipCode} onChange={(e) => handleChange(e)} placeholder='Enter zip code' />
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div>
                                        <Label htmlFor="companyUrl" className="form-label">Company URL</Label>
                                        <Input type="text" className="form-control" id="companyUrl" name='companyUrl' value={inputValues.companyUrl} onChange={(e) => handleChange(e)} placeholder='Enter company url' />
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    {
                                        submittingForm ? <Button color="primary" className="me-2" type='button' disabled>
                                            <Spinner size="sm"> Loading... </Spinner> </Button> : <Button color="primary" type="submit" className="me-2" disabled={submittingForm} onClick={(e) => handleSubmitEditCompanyInfo(companyDetailsData.id, e)}> Update  </Button>
                                    }
                                    <Button color="secondary" type="reset" disabled={submittingForm} onClick={handleResetFormCompanyInfo} > Reset </Button>
                                </Col>

                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>

                <Modal isOpen={assignedCEOModal} toggle={assignedCEOModalToggle}>
                    <ModalHeader toggle={assignedCEOModalToggle}>Edit Assigned CEO</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row className='g-3'>
                                <Col lg={12}>
                                    <div>
                                        <Label htmlFor="sampleInput" className="form-label">Admin CEO</Label>
                                        <Select value={selectedAdminCEO} onChange={(option) => { handleSelectAdminCEO(option); }} options={AdminCEOOptions} styles={selectBoxCustomStyles} />
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    {
                                        submittingForm ? <Button color="primary" className="me-2" type='button' disabled>
                                            <Spinner size="sm"> Loading... </Spinner> </Button> : <Button color="primary" type="submit" className="me-2" disabled={submittingForm} onClick={(e) => handleSubmitEditCompanyCEO(companyDetailsData.id, e)}> Update  </Button>
                                    }
                                    <Button color="secondary" type="reset" disabled={submittingForm} onClick={handleResetFormCompanyCEO} > Reset </Button>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>

                <Modal isOpen={paymentInfoModal} toggle={paymentInfoModalToggle}>
                    <ModalHeader toggle={paymentInfoModalToggle}>Edit Payment Info</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row className='g-3'>


                                <Col lg={12}>
                                    <div>
                                        <Label htmlFor="paymentApplicationInUse" className="form-label">Payment Application In Use</Label>
                                        <Input type="text" className="form-control" id="paymentApplicationInUse" name='paymentApplicationInUse' value={inputValues.paymentApplicationInUse} onChange={(e) => handleChange(e)} placeholder='Enter payment application in use' />
                                    </div>
                                </Col>

                                <Col lg={12}>
                                    <div>
                                        <Label htmlFor="paymentApplicationVersion" className="form-label">Payment Application Version</Label>
                                        <Input type="text" className="form-control" id="paymentApplicationVersion" name='paymentApplicationVersion' value={inputValues.paymentApplicationVersion} onChange={(e) => handleChange(e)} placeholder='Enter payment application version' />
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    {
                                        submittingForm ? <Button color="primary" className="me-2" type='button' disabled>
                                            <Spinner size="sm"> Loading... </Spinner> </Button> : <Button color="primary" type="submit" className="me-2" disabled={submittingForm} onClick={(e) => handleSubmitEditCompanyPaymentInfo(companyDetailsData.id, e)}> Update  </Button>
                                    }
                                    <Button color="secondary" type="reset" disabled={submittingForm} onClick={handleResetCompanyPaymentInfo} > Reset </Button>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>

                <Modal isOpen={merchantBusinessModal} toggle={merchantBusinessModalToggle}>
                    <ModalHeader toggle={merchantBusinessModalToggle}>Edit Merchant Business</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row className='g-3'>
                                {merchantBusinessCategories.map((category, index) => (
                                    <Col lg={12} key={index}>
                                        <div className="form-check form-check-success mb-1">
                                            <Input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`checkbox-${index}`}
                                                checked={selectedMerchantBusiness.includes(category)}
                                                onChange={() => handleMerchantBusinessCheckboxChange(category)}
                                            />
                                            <Label className="form-check-label" htmlFor={`checkbox-${index}`}>
                                                {category}
                                            </Label>
                                        </div>
                                    </Col>
                                ))}

                                <Col lg={12}>
                                    <div>
                                        <Label htmlFor="otherMerchantBusiness" className="form-label">Other</Label>
                                        <Input type="text" className="form-control" id="otherMerchantBusiness" name='otherMerchantBusiness' value={inputValues.otherMerchantBusiness} onChange={(e) => handleChange(e)} placeholder='Other' />
                                    </div>
                                </Col>

                                <Col lg={12}>
                                    {
                                        submittingForm ? <Button color="primary" className="me-2" type='button' disabled>
                                            <Spinner size="sm"> Loading... </Spinner> </Button> : <Button color="primary" type="submit" className="me-2" disabled={submittingForm} onClick={(e) => handleSubmitEditCompanyMerchantBusiness(companyDetailsData.id, e)}> Update  </Button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>

                <Modal size='lg' isOpen={servicesProviderModal} toggle={servicesProviderModalToggle}>
                    <ModalHeader toggle={servicesProviderModalToggle}>Edit Services Provider Business</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row className='g-3'>
                                {servicesProviderCategories.map((category, index) => (
                                    <Col lg={6} key={index}>
                                        <div className="form-check form-check-success mb-1">
                                            <Input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`checkbox-${index}`}
                                                checked={selectedServicesProvider.includes(category)}
                                                onChange={() => handleServicesProviderCheckboxChange(category)}
                                            />
                                            <Label className="form-check-label" htmlFor={`checkbox-${index}`}>
                                                {category}
                                            </Label>
                                        </div>
                                    </Col>
                                ))}

                                <Col lg={7}>
                                    <div>
                                        <Label htmlFor="otherserviceProviderBusiness" className="form-label">Other</Label>
                                        <Input type="text" className="form-control" id="otherserviceProviderBusiness" name='otherserviceProviderBusiness' value={inputValues.otherserviceProviderBusiness} onChange={(e) => handleChange(e)} placeholder='Other' />
                                    </div>
                                </Col>

                                <Col lg={12}>
                                    {
                                        submittingForm ? <Button color="primary" className="me-2" type='button' disabled>
                                            <Spinner size="sm"> Loading... </Spinner> </Button> : <Button color="primary" type="submit" className="me-2" disabled={submittingForm} onClick={(e) => handleSubmitEditCompanyServiceProvider(companyDetailsData.id, e)}> Update  </Button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>

                {/* Set Profile Modal */}
                <Modal isOpen={editCompanyLogoModal} toggle={toggleEditCompanyLogoModal}>
                    <ModalHeader toggle={toggleEditCompanyLogoModal}>Edit Company Logo</ModalHeader>
                    <ModalBody>
                        <ProfileSelector imageForEdit={SERVER_URL + inputValues.file} modalHandler={toggleEditCompanyLogoModal} />
                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}

export default CompanyDetails;