import { flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import { addSportTicketAPI } from '../../apiConfig/apiCall';
import { toast } from 'react-toastify';

const BasicTable = ({ data, columns, customPageSize }) => {
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: customPageSize });
    const [filtering, setFiltering] = useState('');

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: { sorting, pagination, globalFilter: filtering },
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        onGlobalFilterChange: setFiltering,
    });

    const totalPages = table.getPageCount();
    const currentPage = table.getState().pagination.pageIndex;
    const pageNumbersToShow = [];

    const startPage = Math.max(0, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let i = 0; i < totalPages; i++) {
        if (i === 0 || i === 1 || (i >= startPage && i <= endPage) || i === totalPages - 1) {
            pageNumbersToShow.push(i);
        }
    }

    // Calculate the current row display range
    const currentPageSize = table.getState().pagination.pageSize;
    const totalDataCount = data.length;
    const currentRowEnd = Math.min((currentPage + 1) * currentPageSize, totalDataCount);

    // Add Ticket Modal
    const [modal, setModal] = useState(false);
    const toggleAddTicketModal = () => {
        setModal(!modal)
    };

    const [formData, setFormData] = useState({
        subject: "",
        overview: "",
        ticketType: "",
        priority: "",
    });

    const [errors, setErrors] = useState({});

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    // Validate form fields
    const validate = () => {
        const newErrors = {};
        if (!formData.subject) newErrors.subject = "Subject is required";
        if (!formData.overview) newErrors.overview = "Overview is required";
        if (!formData.ticketType) newErrors.ticketType = "Ticket Type is required";
        if (!formData.priority) newErrors.priority = "Priority is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;

        const data = {
            subject: formData.subject,
            overview: formData.overview,
            ticketType: formData.ticketType,
            priority: formData.priority,
        };

        try {
            const apiResponse = await addSportTicketAPI(data);
            if (apiResponse.status === 201) {
                toast.success(apiResponse.data);
                toggleAddTicketModal();
                window.location.reload();
            } else {
                toast.error(apiResponse.data);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <>

            <Card>
                <CardHeader className="border-0 py-2">
                    <Row className="g-4 align-items-center">
                        <div className="col-sm">
                            <div>
                                <h5 className="card-title mb-0">Certification List</h5>
                            </div>
                        </div>
                        <div className="col-sm-auto">
                            <div className="d-flex">
                                <div className='me-2'>
                                    <input className='form-control' type="text" placeholder='Search...' value={filtering} onChange={(e) => setFiltering(e.target.value)} style={{ height: '37px', borderRadius: '.2rem' }} />
                                </div>
                                <div>
                                    <button className="btn btn-danger add-btn" onClick={toggleAddTicketModal}>
                                        <i className="ri-add-line align-bottom"></i>
                                        Create Ticket
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Row>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                    <div className="scroll-y-table">
                        <Table className='table' striped bordered>
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                                                {header.placeholderId ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                                {header.column.getIsSorted() ? (
                                                    header.column.getIsSorted() === 'asc' ?
                                                        <i className="bx bxs-up-arrow ms-2"></i> :
                                                        <i className="bx bxs-down-arrow ms-2"></i>
                                                ) : null}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.length > 0 ? (
                                    table.getRowModel().rows.map(row => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map(cell => (
                                                <td key={cell.id}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={columns.length} className="text-center">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>

                    <div className='mt-2' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="text-muted fs-12">
                            Showing <span className="fw-semibold">{currentRowEnd}</span> of <span className="fw-semibold">{totalDataCount}</span> Results
                        </div>

                        <Pagination size='sm'>
                            <PaginationItem disabled={!table.getCanPreviousPage()}>
                                <PaginationLink onClick={() => table.setPageIndex(0)}>1</PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={!table.getCanPreviousPage()}>
                                <PaginationLink onClick={table.previousPage}>← Prev</PaginationLink>
                            </PaginationItem>

                            {pageNumbersToShow.map(page => (
                                <PaginationItem key={page} active={page === currentPage}>
                                    <PaginationLink onClick={() => table.setPageIndex(page)}>{page + 1}</PaginationLink>
                                </PaginationItem>
                            ))}

                            <PaginationItem disabled={!table.getCanNextPage()}>
                                <PaginationLink onClick={table.nextPage}>Next →</PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={!table.getCanNextPage()}>
                                <PaginationLink onClick={() => table.setPageIndex(totalPages - 1)}>{totalPages}</PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </div>
                </CardBody>
            </Card>


            <Modal
                isOpen={modal}
                toggle={toggleAddTicketModal}
                centered
                size="lg"
                className="border-0"
                modalClassName="zoomIn"
            >

                <ModalHeader toggle={toggleAddTicketModal} className="p-3 bg-info-subtle">
                    Add Ticket
                </ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Row className="g-3">
                            <Col lg={12}>
                                <div>
                                    <Label htmlFor="subject" className="form-label">Subject</Label>
                                    <Input
                                        name="subject"
                                        id="subject"
                                        className="form-control"
                                        placeholder="Enter Subject"
                                        type="text"
                                        value={formData.subject}
                                        onChange={handleChange}
                                    />
                                    {errors.subject && <div className="text-danger">{errors.subject}</div>}
                                </div>
                            </Col>

                            <Col lg={12}>
                                <div>
                                    <Label htmlFor="overview" className="form-label">Overview</Label>
                                    <Input
                                        id="overview"
                                        name="overview"
                                        type="textarea"
                                        style={{ height: "100px" }}
                                        value={formData.overview}
                                        onChange={handleChange}
                                    />
                                    {errors.overview && <div className="text-danger">{errors.overview}</div>}
                                </div>
                            </Col>

                            <Col lg={6}>
                                <Label htmlFor="ticketType" className="form-label">Ticket Type</Label>
                                <Input
                                    name="ticketType"
                                    type="select"
                                    className="form-select"
                                    id="ticketType"
                                    value={formData.ticketType}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Ticket Type</option>
                                    <option value="Query">Query</option>
                                    <option value="Feedback">Feedback</option>
                                    <option value="Report">Report</option>
                                </Input>
                                {errors.ticketType && <div className="text-danger">{errors.ticketType}</div>}
                            </Col>

                            <Col lg={6}>
                                <Label htmlFor="priority" className="form-label">Priority</Label>
                                <Input
                                    name="priority"
                                    type="select"
                                    className="form-select"
                                    id="priority"
                                    value={formData.priority}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Priority</option>
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                </Input>
                                {errors.priority && <div className="text-danger">{errors.priority}</div>}
                            </Col>

                            <Col lg={12}>
                                <Button type="submit" color="primary">Add Ticket</Button>
                                <Button
                                    type="button"
                                    color="secondary"
                                    className="mx-2"
                                    onClick={() => setFormData({
                                        subject: "",
                                        overview: "",
                                        ticketType: "",
                                        priority: "",
                                    })}
                                >
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Form>
            </Modal>

        </>
    );
};

export default BasicTable;