import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import useUserDetails from '../../hooks/useUserDetails';
import { fetchDashboardData } from "../../slices/dashboard/dashboardSlice";
import ActiveCompliance from "./ActiveCompliance";
import ActiveUsers from "./ActiveUsers";
import './dashboard.css';
import { CertificationComplianceChartCard, EvidenceStatusChartCard, OverallComplianceChartCard } from "./DashboardChartCard";
import DepartmentCompliance from "./DepartmentCompliance";
import Loading from "./Loading";
import Widget from "./Widgets";

const Dashboard = () => {

  const dispatch = useDispatch();
  const { loading, widgetData, data: dashboardData } = useSelector(state => state.Dashboard);
  
  const { CLIENT_ID, CURRENT_ROLE } = useUserDetails();
  const role = CURRENT_ROLE;

  useEffect(() => {
    if (CLIENT_ID) {
      dispatch(fetchDashboardData(CLIENT_ID));
    }
  }, [CLIENT_ID, dispatch]);

  const colSize = (() => {
    switch (role) {
      case 'AUDITOR':
        return 4;
      case 'CLIENT_ADMIN':
        return 3;
      default:
        return 3;
    }
  })();

  if (loading) return <Loading />

  return (
    <>
      <div className="page-content dashboard-page">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <Widget
                    usersCount={widgetData.usersCount}
                    auditorsCount={widgetData.auditorsCount}
                    policiesCount={widgetData.policiesCount}
                    assetsCount={widgetData.assetsCount}
                    colSize={colSize}
                    role={role}
                    loading={loading}
                  />
                </Row>
                <Row>
                  <Col xl={6} lg={12}>
                    <Row>
                      <OverallComplianceChartCard title="Overall Compliance" overallComplianceData={dashboardData.overallCompliance} />
                      <EvidenceStatusChartCard title="Evidence Status" evidenceStatusData={dashboardData.evidenceStatus} />
                      {console.log(dashboardData.certificationCompliance)}
                      <CertificationComplianceChartCard title="Certification Compliance" certificationComplianceData={
                        dashboardData.certificationCompliance.filter(
                          (item) => item.status === "ACTIVE"
                        )
                        } />
                    </Row>
                  </Col>
                  <Col xl={6} lg={12} className="pe-1 ps-1">
                    <DepartmentCompliance title="Department Compliance" heightMaxer={dashboardData.certificationCompliance} departmentComplianceData={dashboardData.departmentCompliance} />
                  </Col>
                </Row>
                <Row>
                  <ActiveCompliance title="Active Compliance" activeComplianceData={dashboardData.activeCompliance} />
                  <ActiveUsers title="Active Users" activeUsersData={dashboardData.activeUsers} />
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;