import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";
import { toast } from 'react-toastify';
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Swal from 'sweetalert2';
import { addPolicyApi, deletePoliciesApi, downloadPoliciesApi } from '../../apiConfig/apiCall';
import useReactSelectCustomStyle from '../../hooks/useReactSelectCustomStyle';
import BasicTable from './BasicTable';
import useUserDetails from '../../hooks/useUserDetails';

const PolicyTable = ({ policiesData, getPolicies }) => {

    const { CLIENT_ID, CURRENT_ROLE } = useUserDetails();

    const role = CURRENT_ROLE;

    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);

    const data = policiesData;

    const columns = [
        {
            header: "S.No",
            accessorKey: "id",
            cell: ({ row }) => (
                <span className="fw-semibold">{row.index + 1}</span>
            ),
        },
        {
            header: "Title",
            accessorKey: "title",
        },
        {
            header: "File",
            accessorKey: "file",
            cell: (info) => {
                const fileName = info.getValue().split('/').pop();
                return fileName;
            },
        },
        {
            header: "Status",
            accessorKey: "status",
        },
        {
            header: "Entry Date",
            accessorKey: "entryDate",
        },
        {
            header: "Actions",
            accessorKey: "actions",
            cell: ({ row }) => (
                <div className="d-flex gap-2">
                    <Link to="#" onClick={() => handleDownloadPolicy(row.original.id, row.original.file)} className="text-primary fs-5">
                        <i className='bx bxs-download'></i>
                    </Link>
                    {
                        role === 'CLIENT_ADMIN' && (
                            <Link to="#" onClick={() => handleDelete(row.original.id)} className="text-danger fs-5">
                                <i className='bx bxs-trash-alt'></i>
                            </Link>
                        )
                    }
                </div>
            ),
            enableSorting: false,
        },
    ]

    // Add Policy Modal
    const [policyAddModalOpen, setPolicyAddModalOpen] = useState(false);
    const togglePolicyAddModal = () => setPolicyAddModalOpen(!policyAddModalOpen);

    const selectBoxCustomStyles = useReactSelectCustomStyle()
    const [selectedStatus, setSelectedStatus] = useState(null);

    const StatusOptions = [
        { value: 'Applied', label: 'Applied' },
        { value: 'Not-Applied', label: 'Not-Applied' },
    ];

    function handleSelectStatus(selectedStatus) {
        setSelectedStatus(selectedStatus);
    }

    const handleDelete = async (POLICY_ID) => {
        const result = await Swal.fire({
            width: '20rem',
            padding: '0 0 1rem 0',
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        });

        if (result.isConfirmed) {
            const apiResponse = await deletePoliciesApi(POLICY_ID);
            if (apiResponse.status === 200) {
                toast.success(apiResponse.data);
                getPolicies(CLIENT_ID);
            }
            Swal.fire({
                width: '20rem',
                padding: '0 0 1rem 0',
                title: "Deleted!",
                text: 'Policy has been deleted.',
                icon: "success",
            });
        }
    };

    // Download Policy
    const handleDownloadPolicy = async (POLICY_ID, filename) => {
        try {
            const fileNameFormatDownload = filename.split('/').pop();
            const apiResponse = await downloadPoliciesApi(POLICY_ID, 'blob');
            if (apiResponse.status === 200) {
                const blob = new Blob([apiResponse.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileNameFormatDownload);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            } else {
                toast.error('This feature is under development.');
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Add Policy Logic
    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            const validExtensions = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
            if (validExtensions.includes(uploadedFile.type)) {
                setFile(uploadedFile);
            } else {
                setFile(null);
                toast.error('Please upload a valid PDF or Word document.');
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!file) {
            toast.error('Please upload a valid PDF or Word document.');
            return;
        }

        if (!title.trim()) {
            toast.error('Title is required.');
            return;
        }

        if (!selectedStatus || !selectedStatus.value) {
            toast.error('Status is required.');
            return;
        }
        const data = new FormData();
        data.append('file', file);
        data.append('title', title);
        data.append('status', selectedStatus.value);

        console.log(file, title, selectedStatus.value)

        try {
            const apiResponse = await addPolicyApi(data);
            console.log(apiResponse)
            if (apiResponse.status === 200) {
                setPolicyAddModalOpen(false);
                toast.success(apiResponse.data.message);
                getPolicies(CLIENT_ID);
            }
        } catch (error) {
            console.log('Error uploading file:', error);
        }
    }


    useEffect(() => {
        getPolicies(CLIENT_ID);
    }, [CLIENT_ID]);

    return (
        <>
            <BasicTable
                columns={columns}
                data={data}
                customPageSize={10}
                onclickHandler={togglePolicyAddModal}
            />

            {/* Add Policy Modal */}
            <Modal size='md' isOpen={policyAddModalOpen} toggle={togglePolicyAddModal}>
                <ModalHeader toggle={togglePolicyAddModal}>Add Policy</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit}>
                        <Row className="gy-4">
                            <Col lg={6}>
                                <div>
                                    <Label htmlFor="companyNameInput" className="form-label">Title</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="companyNameInput"
                                        placeholder='Enter title'
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div>
                                    <Label htmlFor="statusSelect" className="form-label">Status</Label>
                                    <Select
                                        id="statusSelect"
                                        value={selectedStatus}
                                        onChange={handleSelectStatus}
                                        options={StatusOptions}
                                        styles={selectBoxCustomStyles}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div>
                                    <Label htmlFor="fileInput" className="form-label">PDF/Word File</Label>
                                    <Input
                                        type="file"
                                        className="form-control"
                                        id="fileInput"
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <Button color="primary" className='btn-sm mt-4' type='submit'> Add Policy </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

        </>
    )
}

export default PolicyTable;