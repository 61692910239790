import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';

export default function Loading() {

    const [text, setText] = useState("Dashboard");

    useEffect(() => {
        const textLoad = () => {
            setTimeout(() => setText("Dashboard"), 0);
            setTimeout(() => setText("Assets"), 4000);
            setTimeout(() => setText("Evidences"), 8000);
            setTimeout(() => setText("Project"), 12000);
        };

        textLoad();
        const interval = setInterval(textLoad, 12000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="dashboard-loader-xzlenz-client">
            <div className="container" style={{ position: 'relative', zIndex: 99999, display: 'flex', alignItems: 'center' }}>
                <Spinner color='primary' className='me-3'>Loading</Spinner>
                <span className="text first-text me-2">Loading </span>
                <span className="text sec-text">{text}</span>
            </div>
        </div>
    );
}