import { useRef, useState } from "react";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import setCanvasPreview from "./setCanvasPreview";
import { Button, Input, Spinner } from "reactstrap";
import axios from "axios";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import { uploadUserProfileImageApi } from "../../apiConfig/apiCall";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 130;

const ImageCropper = ({ ssoId, modalHandler }) => {

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [imgSrc, setImgSrc] = useState("");
    const [crop, setCrop] = useState();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const onSelectFile = (e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const imageElement = new Image();
            const imageUrl = reader.result?.toString() || "";
            imageElement.src = imageUrl;

            imageElement.addEventListener("load", (e) => {
                if (error) setError("");
                const { naturalWidth, naturalHeight } = e.currentTarget;
                if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
                    setError("Image must be at least 150 x 150 pixels.");
                    return setImgSrc("");
                }
            });
            setImgSrc(imageUrl);
        });
        reader.readAsDataURL(file);
    };

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

        const crop = makeAspectCrop(
            {
                unit: "%",
                width: cropWidthInPercent,
            },
            ASPECT_RATIO,
            width,
            height
        );
        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
    };

    const handleCropImage = async () => {
        setLoading(true);

        setCanvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            convertToPixelCrop(
                crop,
                imgRef.current.width,
                imgRef.current.height
            )
        );

        const dataUrl = previewCanvasRef.current.toDataURL();

        const response = await fetch(dataUrl);
        const blob = await response.blob();

        const file = new File([blob], "cropped-image.png", { type: "image/png" });

        const formData = new FormData();
        formData.append("file", file);

        try {
            const apiResponse = await uploadUserProfileImageApi(ssoId, formData);
            if (apiResponse.status === 200) {
                setLoading(true);
                toast.success(apiResponse.data.message);
                modalHandler(false);
                window.location.reload();
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            setLoading(true);
        }
    };

    return (
        <>
            <Input
                className="mb-2"
                type="file"
                accept="image/*"
                onChange={onSelectFile}
            />
            {error && <p className="text-danger">{error}</p>}
            {imgSrc && (
                <>
                    <div className="d-flex justify-content-between">
                        <div className="me-3">
                            <ReactCrop
                                crop={crop}
                                onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                                circularCrop
                                keepSelection
                                aspect={ASPECT_RATIO}
                                minWidth={MIN_DIMENSION}
                            >
                                <img
                                    ref={imgRef}
                                    src={imgSrc}
                                    alt="Upload"
                                    style={{ maxHeight: "70vh" }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        </div>

                        <div>
                            {crop && (
                                <div className="d-flex justify-content-center">
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: "1px solid black",
                                            objectFit: "contain",
                                            width: 150,
                                            height: 150,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        {
                            loading ? <Button color="primary" disabled > <Spinner size="sm"> Loading... </Spinner> <span> {' '}Uploading ... </span> </Button> : <Button color="primary" className="mb-2" onClick={handleCropImage} > Crop Image </Button>
                        }
                    </div>
                </>
            )}
        </>
    );
};

export default ImageCropper;