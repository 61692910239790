import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});


axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      Object.keys(Cookies.get()).forEach((cookieName) => {
        Cookies.remove(cookieName);
      });
      toast.error('Unauthorized Access!');
      const navigate = useNavigate();
      navigate('/login');
    }
    return Promise.reject(error);
  }
);

export const commonRequest = async (
  method,
  url,
  body = {},
  customHeaders = {},
  responseType = 'json'
) => {
  try {
    const response = await axiosInstance({
      method,
      url,
      data: body,
      headers: { ...customHeaders },
      responseType,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};