import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import Swal from 'sweetalert2';
import { addUser, deleteUser, editUser, getSingleUser } from '../../apiConfig/apiCall';
import dummyUserImage from '../../assets/images/users/user-dummy-img.jpg';
import BasicTable from './BasicTable';
import ProfileSelector from "./ProfileSelector";

const UsersTable = ({ users, userRole, getUsers, CLIENT_ID }) => {

    // State For Handle Forms
    const [submittingForm, setSubmittingForm] = useState(false);

    const SERVER_URL = process.env.REACT_APP_API_IMAGE_URL;

    const data = users;

    const columns = [
        {
            header: "",
            accessorKey: "profileImage",
            cell: ({ getValue, row }) => (
                <div className="profile-user position-relative d-inline-block">
                    <img src={`${SERVER_URL}${getValue()}?t=${new Date().getTime()}`}
                        onError={(e) => { e.target.src = dummyUserImage; }}
                        className="rounded-circle avatar-xs img-thumbnail user-profile-image material-shadow"
                        alt="user-profile" />
                    <div className="avatar-xs p-0 rounded-circle profile-photo-edit" onClick={() => toggleUserProfileImgUploadModal(SERVER_URL + getValue(), row.original.ssoId)}>
                        <Input id="profile-img-file-input" type="file"
                            className="profile-img-file-input" />
                        <Label htmlFor="profile-img-file-input"
                            className="profile-photo-edit" style={{ height: '1.2rem', width: '1.2rem', left: '1.5rem', top: '.8rem' }}>
                            <span className="avatar-title rounded-circle bg-light text-body material-shadow">
                                <i className="ri-camera-fill"></i>
                            </span>
                        </Label>
                    </div>
                </div>
            ),
            enableSorting: false,
        },
        {
            header: "S.No",
            accessorKey: "id",
            cell: ({ row }) => (
                <span className="fw-semibold">{row.index + 1}</span>
            ),
        },
        {
            header: "First Name",
            accessorKey: "firstName",
        },
        {
            header: "Last Name",
            accessorKey: "lastName",
        },
        {
            header: "Designation",
            accessorKey: "designation",
        },
        {
            header: "Email",
            accessorKey: "email",
        },
        {
            header: "Actions",
            accessorKey: "actions",
            cell: ({ row }) => (
                <div className="d-flex gap-2">
                    <Link to="#" onClick={() => handleEditUser(row.original)} className="text-primary fs-5">
                        <i className='bx bxs-edit'></i>
                    </Link>
                    <Link to="#" onClick={() => handleDelete(row.original.ssoId)} className="text-danger fs-5">
                        <i className='bx bxs-trash-alt'></i>
                    </Link>
                </div>
            ),
            enableSorting: false,
        },
    ]
    // Get All Users List End

    // Open Delete User Start
    const deleteUserFunction = async (ssoId) => {
        try {
            const apiResponse = await deleteUser(ssoId);
            Swal.fire({
                width: '20rem',
                padding: '0 0 1rem 0',
                title: "Deleted!",
                text: apiResponse.data.message,
                icon: "success",
            });
        } catch (error) {
            console.log(error)
        }
    }
    const handleDelete = (ssoId) => {
        Swal.fire({
            width: '20rem',
            padding: '0 0 1rem 0',
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUserFunction(ssoId).then(() => {
                    getUsers(userRole, CLIENT_ID);
                });
            }
        });
    };
    // Open Delete User End

    // Add Auditor Logic Start
    const [userAddModalOpen, setUserAddModalOpen] = useState(false);
    const toggleUserAddModal = () => setUserAddModalOpen(!userAddModalOpen);

    const [addUserInputValues, setAddUserInputValues] = useState({ ssoId: "", firstName: "", lastName: "", email: "", contactNumber: "", designation: "", professionalQualification: "", clientCompanyId: "", password: "" });

    const handleUserAddInputsChange = (event) => {
        setAddUserInputValues({ ...addUserInputValues, [event.target.name]: event.target.value })
    }

    const handleAddUser = async (e) => {
        e.preventDefault();
        try {
            const { ssoId, firstName, lastName, email, contactNumber, designation, professionalQualification, password } = addUserInputValues;

            if (!ssoId) {
                toast.error('SSO ID is required');
                return;
            } if (ssoId.length < 5 || ssoId.length > 12) {
                toast.error('SSO ID must be 5 to 12 characters long');
                return;
            }

            else if (!firstName) {
                toast.error('First Name is required');
                return;
            } else if (firstName.length > 15) {
                toast.error('First Name cannot exceed 15 characters');
                return;
            }


            else if (!lastName) {
                toast.error('Last Name is required');
                return;
            } else if (lastName.length > 15) {
                toast.error('Last Name cannot exceed 15 characters');
                return;
            }

            else if (!email) {
                toast.error('Email is required');
                return;
            } else if (email.length > 50) {
                toast.error('Email cannot exceed 50 characters');
                return;
            } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i.test(email)) {
                toast.error('Please enter a valid email address');
                return;
            }

            else if (!contactNumber) {
                toast.error('Contact Number is required');
                return;
            } else if (!/^\d{8,15}$/.test(contactNumber)) {
                toast.error('Contact Number must be between 8 and 15 digits');
                return;
            }

            else if (!designation) {
                toast.error('Designation is required');
                return;
            } else if (designation.length > 50) {
                toast.error('Designation must not exceed 50 characters');
                return;
            }

            else if (!professionalQualification) {
                toast.error('Professional qualification is required');
                return;
            } else if (professionalQualification.length > 50) {
                toast.error('Professional qualification must not exceed 50 characters');
                return;
            }

            if (!password) {
                toast.error('Password is required');
                return;
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"\'<>,.?/-]).{8,15}$/.test(password)) {
                toast.error('Wrong password format. Password must be 8 to 15 characters, with at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character');
                return;
            }

            const data = {
                ssoId, firstName, lastName, email, contactNumber, designation, professionalQualifications: professionalQualification, clientCompanyId: CLIENT_ID, password
            };

            setSubmittingForm(true);

            const apiResponse = await addUser(data, userRole);

            if (apiResponse.status === 201) {
                toast.success(apiResponse.data);
                setUserAddModalOpen(false);
                getUsers(userRole, CLIENT_ID);
            } else if (apiResponse.status === 400) {
                toast.error(apiResponse.data);
            } else if (apiResponse.status === 409) {
                toast.error(apiResponse.data);
            } else if (apiResponse.status === 202) {
                toast.error(apiResponse.data);
            } else {
                toast.error('Something went wrong while adding user');
            }
        } catch (error) {
            console.error('Error sending data:', error);
            toast.error('An error occurred while adding user');
        } finally {
            setSubmittingForm(false);
        }
    }
    const handleResetForm = async () => {
        setAddUserInputValues({ ssoId: "", firstName: "", lastName: "", email: "", contactNumber: "", designation: "", professionalQualification: "", clientCompanyId: "", password: "" });
    }
    // Add Auditor Logic End

    // Upload User Profile Image Logic
    const [userProfileImgUploadModal, setUserProfileImgUploadModal] = useState(false);
    const [currentToggledUserProfileImgUrl, setCurrentToggledUserProfileImgUrl] = useState('');
    const [currentUserSsoIdForEditProfile, setCurrentUserSsoIdForEditProfile] = useState('');
    const toggleUserProfileImgUploadModal = (imagePath, SSO_ID) => {
        setCurrentToggledUserProfileImgUrl(imagePath);
        setCurrentUserSsoIdForEditProfile(SSO_ID);
        setUserProfileImgUploadModal(!userProfileImgUploadModal);
    }

    // Open Edit User Modal Start
    const [isUserEditModalOpen, setIsUserEditModalOpen] = useState(false);
    const [inputValues, setInputValues] = useState({
        ssoId: '',
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        designation: '',
        professionalQualification: '',
        clientCompanyId: ''
    });

    const toggleUserEditModal = () => setIsUserEditModalOpen(!isUserEditModalOpen);

    const handleChange = (e) => {
        setInputValues({
            ...inputValues,
            [e.target.name]: e.target.value
        });
    };

    const handleEditUser = async (user) => {
        setIsUserEditModalOpen(true);
        try {
            const apiResponse = await getSingleUser(user.ssoId);
            const userData = apiResponse.data;

            setInputValues({
                ssoId: userData.ssoId || '',
                firstName: userData.firstName || '',
                lastName: userData.lastName || '',
                email: userData.email || '',
                contactNumber: userData.contactNumber || '',
                designation: userData.designation || '',
                professionalQualification: userData.professionalQualifications || '',
                clientCompanyId: userData.clientCompanyId || ''
            });

        } catch (error) {
            console.error("Error fetching user data: ", error);
        }
    };

    const handleSubmitEditUser = async (e) => {
        try {
            e.preventDefault();
            const { ssoId, firstName, lastName, email, contactNumber, designation, professionalQualification, clientCompanyId } = inputValues;

            if (!firstName) {
                toast.error('First Name is required');
                return;
            } else if (firstName.length > 15) {
                toast.error('First Name cannot exceed 15 characters');
                return;
            }


            else if (!lastName) {
                toast.error('Last Name is required');
                return;
            } else if (lastName.length > 15) {
                toast.error('Last Name cannot exceed 15 characters');
                return;
            }

            else if (!email) {
                toast.error('Email is required');
                return;
            } else if (email.length > 50) {
                toast.error('Email cannot exceed 50 characters');
                return;
            } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i.test(email)) {
                toast.error('Please enter a valid email address');
                return;
            }

            else if (!contactNumber) {
                toast.error('Contact Number is required');
                return;
            } else if (!/^\d{8,15}$/.test(contactNumber)) {
                toast.error('Contact Number must be between 8 and 15 digits');
                return;
            }

            else if (!designation) {
                toast.error('Designation is required');
                return;
            } else if (designation.length > 50) {
                toast.error('Designation must not exceed 50 characters');
                return;
            }

            else if (!professionalQualification) {
                toast.error('Professional qualification is required');
                return;
            } else if (professionalQualification.length > 50) {
                toast.error('Professional qualification must not exceed 50 characters');
                return;
            }

            const data = {
                firstName, lastName, email, contactNumber, designation, professionalQualifications: professionalQualification, clientCompanyId
            };
            setSubmittingForm(true);
            const apiResponse = await editUser(data, ssoId);
            if (apiResponse.status === 200) {
                toast.success(apiResponse.data);
                setIsUserEditModalOpen(false);
                getUsers(userRole, CLIENT_ID);
            } else if (apiResponse.status === 202) {
                toast.error(apiResponse.data.data);
            } else {
                toast.error('Something went wrong while adding user');
            }
        } catch (error) {
            console.error('Error sending data:', error);
            toast.error('An error occurred while updating user');
        } finally {
            setSubmittingForm(false);
        }
    }
    // Open Edit User Modal End


    return (
        <>
            <BasicTable
                columns={columns}
                data={data}
                customPageSize={10}
                onclickHandlerAddUser={toggleUserAddModal}
            />

            {/* Edit User Modal */}
            <Modal isOpen={isUserEditModalOpen} toggle={toggleUserEditModal} size="lg">
                <ModalHeader toggle={toggleUserEditModal}>Edit User - {inputValues.ssoId}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <Form>
                                <Row className="gy-4">

                                    <Col lg={4}>
                                        <div>
                                            <Label htmlFor="firstName" className="form-label">First Name</Label>
                                            <Input type="text" className="form-control" id="firstName" placeholder='Enter first name' name='firstName' value={inputValues.firstName} onChange={(e) => handleChange(e)} />
                                        </div>
                                    </Col>

                                    <Col lg={4}>
                                        <div>
                                            <Label htmlFor="lastName" className="form-label">Last Name</Label>
                                            <Input type="text" className="form-control" id="lastName" placeholder='Enter last name' name='lastName' value={inputValues.lastName} onChange={(e) => handleChange(e)} />
                                        </div>
                                    </Col>

                                    <Col lg={4}>
                                        <div>
                                            <Label htmlFor="email" className="form-label">Email Address</Label>
                                            <Input type="email" className="form-control" id="email" placeholder='Enter email address' name='email' value={inputValues.email} onChange={(e) => handleChange(e)} />
                                        </div>
                                    </Col>

                                    <Col lg={4}>
                                        <div>
                                            <Label htmlFor="contactNumber" className="form-label">Phone Number</Label>
                                            <Input type="text" className="form-control" id="contactNumber" placeholder='Enter phone number' name='contactNumber' value={inputValues.contactNumber} onChange={(e) => handleChange(e)} />
                                        </div>
                                    </Col>

                                    <Col lg={4}>
                                        <div>
                                            <Label htmlFor="designation" className="form-label">Designation</Label>
                                            <Input type="text" className="form-control" id="designation" placeholder='Enter designation' name='designation' value={inputValues.designation} onChange={(e) => handleChange(e)} />
                                        </div>
                                    </Col>

                                    <Col lg={4}>
                                        <div>
                                            <Label htmlFor="companyNameInput" className="form-label">Professional Qualifications</Label>
                                            <Input type="text" className="form-control" id="professionalQualification" placeholder='Enter professional qualification' name='professionalQualification' value={inputValues.professionalQualification} onChange={(e) => handleChange(e)} />
                                        </div>
                                    </Col>


                                    <Col lg={12}>
                                        {
                                            submittingForm ? <Button color="primary" className="me-2" type='button' disabled>
                                                <Spinner size="sm"> Loading... </Spinner> </Button> : <Button color="primary" type="submit" className="me-2" disabled={submittingForm} onClick={handleSubmitEditUser}> Update Auditor  </Button>
                                        }
                                        <Button color="secondary" type="reset" disabled={submittingForm} onClick={() => setInputValues({ ssoId: inputValues.ssoId, firstName: "", lastName: "", email: "", contactNumber: "", designation: "", professionalQualification: "", })}> Reset </Button>
                                    </Col>

                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            {/* Add Auditor Modal */}
            <Modal size='lg' isOpen={userAddModalOpen} toggle={toggleUserAddModal}>
                <ModalHeader toggle={toggleUserAddModal}>Add Auditor</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row className="gy-4">
                            <Col lg={4}>
                                <div>
                                    <Label htmlFor="ssoId" className="form-label">Username (SSO ID)</Label>
                                    <Input type="text" className="form-control" id="ssoId" placeholder='Enter username (SSO ID)' name='ssoId' value={addUserInputValues.ssoId} onChange={(e) => handleUserAddInputsChange(e)} />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div>
                                    <Label htmlFor="firstName" className="form-label">First Name</Label>
                                    <Input type="text" className="form-control" id="firstName" placeholder='Enter first name' name='firstName' value={addUserInputValues.firstName} onChange={(e) => handleUserAddInputsChange(e)} />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div>
                                    <Label htmlFor="lastName" className="form-label">Last Name</Label>
                                    <Input type="text" className="form-control" id="lastName" placeholder='Enter last name' name='lastName' value={addUserInputValues.lastName} onChange={(e) => handleUserAddInputsChange(e)} />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div>
                                    <Label htmlFor="email" className="form-label">Email Address</Label>
                                    <Input type="email" className="form-control" id="email" placeholder='Enter email address' name='email' value={addUserInputValues.email} onChange={(e) => handleUserAddInputsChange(e)} />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div>
                                    <Label htmlFor="contactNumber" className="form-label">Phone Number</Label>
                                    <Input type="text" className="form-control" id="contactNumber" placeholder='Enter phone number' name='contactNumber' value={addUserInputValues.contactNumber} onChange={(e) => handleUserAddInputsChange(e)} />
                                </div>
                            </Col>

                            <Col lg={4}>
                                <div>
                                    <Label htmlFor="designation" className="form-label">Designation</Label>
                                    <Input type="text" className="form-control" id="designation" placeholder='Enter designation' name='designation' value={addUserInputValues.designation} onChange={(e) => handleUserAddInputsChange(e)} />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div>
                                    <Label htmlFor="companyNameInput" className="form-label">Professional Qualifications</Label>
                                    <Input type="text" className="form-control" id="professionalQualification" placeholder='Enter professional qualification' name='professionalQualification' value={addUserInputValues.professionalQualification} onChange={(e) => handleUserAddInputsChange(e)} />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div>
                                    <Label htmlFor="password" className="form-label">Password</Label>
                                    <Input type="password" className="form-control" id="password" name='password' placeholder='Enter password' value={addUserInputValues.password} onChange={(e) => handleUserAddInputsChange(e)} />
                                </div>
                            </Col>

                            <Col lg={12}>
                                {
                                    submittingForm ? <Button color="primary" className="me-2" type='button' disabled>
                                        <Spinner size="sm"> Loading... </Spinner> </Button> : <Button color="primary" type="submit" className="me-2" disabled={submittingForm} onClick={handleAddUser}> Add Auditor  </Button>
                                }
                                <Button color="secondary" type="reset" disabled={submittingForm} onClick={handleResetForm}> Reset </Button>
                            </Col>

                        </Row>
                    </Form>
                </ModalBody>
            </Modal>


            {/* Upload Profile Image */}
            <Modal isOpen={userProfileImgUploadModal} toggle={toggleUserProfileImgUploadModal}>
                <ModalHeader toggle={toggleUserProfileImgUploadModal}>Upload Profile Image</ModalHeader>
                <ModalBody>
                    <ProfileSelector image={currentToggledUserProfileImgUrl} ssoId={currentUserSsoIdForEditProfile} modalHandler={toggleUserProfileImgUploadModal} />
                </ModalBody>
            </Modal>

        </>
    )
}

export default UsersTable;