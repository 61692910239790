import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './assets/scss/themes.scss';
import Toaster from './Components/Common/Toaster';
import Route from './Routes';

function App() {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    if (token) {
      localStorage.setItem('token', token);
      navigate('/dashboard');
    }
  }, [location.search]);

  return (
    <React.Fragment>
      <Toaster />
      <Route />
    </React.Fragment>
  );
}

export default App;