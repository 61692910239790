import classnames from "classnames";
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import "react-perfect-scrollbar/dist/css/styles.css";
import { toast } from 'react-toastify';
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent } from 'reactstrap';
import { addCommentApi, deleteEvidenceApi, downloadEvidenceApi, freezEvidenceAPI, getCCFStatusAPI, getCetificationComments, getCetificationEvidence, getComplianceListApi, getMappedPointWithCCF, getProjectTaskWithCCF, getProjectTaskWithOtherCertification, getReferencesApi, uploadEvidenceApi } from '../../../apiConfig/apiCall';
import slack from '../../../assets/images/brands/slack.png';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import ActivityCanvas from '../../../Components/ProjectTaskComponents/CCFComponents/ActivityCanvas';
import CCFData from '../../../Components/ProjectTaskComponents/CCFComponents/CCFData';
import ComplianceCanvas from '../../../Components/ProjectTaskComponents/ComplianceCanvas';
import OtherComplianceData from '../../../Components/ProjectTaskComponents/OtherComplianceComponents/OtherComplianceData';
import { useLocation } from "react-router-dom";
import useUserDetails from "../../../hooks/useUserDetails";

const ProjectTask = () => {

    const { CLIENT_ID, CURRENT_ROLE,SSO_ID } = useUserDetails();

    const location = useLocation();
    const extractValueOfCompliance = () => {
        const query = location.search;
        return query.substring(1);
    };

    const SERVER_URL_2 = process.env.REACT_APP_API_IMAGE_URL_2;

    const currentUser = SSO_ID;
    const role = CURRENT_ROLE;

    const [loading, setLoading] = useState(false);

    const [defaultComplianceTabName, setDefaultComplianceTabName] = useState('');
    const [otherComplianceTabName, setOtherComplianceTabName] = useState('');
    const [otherComplianceData, setOtherComplianceData] = useState([]);
    const [originalOtherComplianceData, setOriginalOtherComplianceData] = useState([]);
    const [activeFilter, setActiveFilter] = useState('All');
    const [currentOtherComplianceNameShow, setCurrentOtherComplianceNameShow] = useState('');

    const [pid, setPid] = useState();
    const [arrowNavTab, setarrowNavTab] = useState(extractValueOfCompliance() || 'CCF');
    const [isRight, setIsRight] = useState(false);
    const [topBorderTab, settopBorderTab] = useState("1");
    const [activeTab, setActiveTab] = useState("");
    const [complianceOffCanvas, setComplianceOffCanvas] = useState(false);

    const [evidenceFile, setEvidenceFile] = useState([]);
    const [idForFileUpload, setIdForFileUpload] = useState();
    const [idForDeleteFile, setIdForDeleteFile] = useState();
    const [activityCommentsTab, setActivityCommentsTab] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [referencesData, setReferencesData] = useState([]);
    const [messages, setMessages] = useState([]);
    const [mappedPointsData, setMappedPointsData] = useState([]);
    const [mappedComplianceName, setMappedComplianceName] = useState('');
    const [ccfNameTitle, setCCFNameTitle] = useState('');

    // Get Other Compliance Data APi Logic Start
    const [pcid, setPcid] = useState()
    const getOtherComplianceData = async (CLIENT_ID, certificationName) => {
        try {
            setLoading(true);
            const apiResponse = await getProjectTaskWithOtherCertification(CLIENT_ID, certificationName);
            if (apiResponse.status === 200) {
                if (certificationName !== "CCF") {
                    setPid(apiResponse.data.id);
                    setOtherComplianceData(apiResponse.data.projectCertification[0].cra);
                    setOriginalOtherComplianceData(apiResponse.data.projectCertification[0].cra);
                    if (otherComplianceTabName) {
                        const matchedCompliance = otherComplianceTabName.find(item => item.title === certificationName);

                        if (matchedCompliance.title) {
                            apiResponse.data.projectCertification.map((data) => {
                                setPcid(data.id);
                            });
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    // Get Other Compliance Data APi Logic Start



    const arrowNavToggle = async (tab) => {
        if (arrowNavTab !== tab) {
            setarrowNavTab(tab);
            setActiveFilter('All');
            setCurrentOtherComplianceNameShow(tab)
            getOtherComplianceData(CLIENT_ID, tab);
        }
    };


    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };
    const topBordertoggle = (tab) => {
        if (topBorderTab !== tab) settopBorderTab(tab);
    }
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const toggleComplianceOffCanvas = () => {
        setComplianceOffCanvas(!complianceOffCanvas);
    };

    // Get Compliance List APi Logic Start
    const getComplianceList = async () => {
        try {
            const apiResponse = await getComplianceListApi(CLIENT_ID);

            if (apiResponse.status === 200) {

                if (role === "AUDITOR") {
                    const transformedData = apiResponse.data.filter(auditorP => auditorP.dba === currentUser || auditorP.certificationType === "CCF").map(item => ({
                        id: item.id,
                        title: item.certificationType,
                        imageSrc: item.logo ? item.logo : slack
                    }));
                    setDefaultComplianceTabName(transformedData[0]);
                    const remainingOtherCompliance = transformedData.slice(1);
                    setOtherComplianceTabName(remainingOtherCompliance);
                } else {
                    const transformedData = apiResponse.data.map(item => ({
                        id: item.id,
                        title: item.certificationType,
                        imageSrc: item.logo ? item.logo : slack
                    }));
                    setDefaultComplianceTabName(transformedData[0]);
                    const remainingOtherCompliance = transformedData.slice(1);
                    setOtherComplianceTabName(remainingOtherCompliance);
                }
            } else {
                toast.error("Error getting compliance");
            }
        } catch (error) {
            console.error('Error fetching compliance data:', error);
        }
    };
    // Get Compliance List APi Logic Start


    const [ccfStatusList, setCcfStatusList] = useState([]);
    const toggleEvidence = async (id, pid, nameOfCCF) => {
        try {
            setCCFNameTitle(nameOfCCF);
            setIsRight(true);
            setIdForDeleteFile(id);
            setIdForFileUpload(id);
            getCetificationCommentsData(id, pid);
            getReferences(id, pid)
            const apiResponse = await getCetificationEvidence(id, pid);
            setEvidenceFile(apiResponse.data);

            const ccfStatusResponse = await getCCFStatusAPI(id, pid);
            if (ccfStatusResponse.status === 200) {
                setCcfStatusList(ccfStatusResponse.data);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const deleteEvidence = async (id, pid, filename) => {
        try {
            const data = new FormData();
            data.append("id", id);
            data.append("pid", pid);
            data.append("filename", filename);

            const apiResponse = await deleteEvidenceApi(data);
            if (apiResponse.status === 200) {
                toast.success(apiResponse.data);

                // Fetch the updated evidence data
                const updatedEvidenceResponse = await getCetificationEvidence(id, pid);
                setEvidenceFile(updatedEvidenceResponse.data); // Update the state with the new data
            } else {
                toast.error(apiResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const freezEvidence = async (id, pid, filename) => {
        try {
            const data = new FormData();
            data.append("id", id);
            data.append("pid", pid);
            data.append("filename", filename);
            const apiResponse = await freezEvidenceAPI(data);
            if (apiResponse.status === 200) {
                toast.success(apiResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const downloadEvidence = async (id, pid, filename) => {
        try {
            const filePath = filename;
            const fileNameFormatDownload = filename.split(/[/\\]/).pop()
            const encodedFilePath = encodeURIComponent(filePath);
            const apiResponse = await downloadEvidenceApi(id, pid, encodedFilePath, 'blob');
            if (apiResponse.status === 200) {
                const blob = new Blob([apiResponse.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileNameFormatDownload);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            }
        } catch (error) {
            console.log('Error while downloading file:', error);
        }
    };

    const getCetificationCommentsData = async (id, pid) => {
        try {
            const apiResponse = await getCetificationComments(id, pid);
            if (apiResponse.status === 200) {
                setMessages(apiResponse.data);
                const commentTabs = Object.keys(apiResponse.data);
                setActivityCommentsTab(commentTabs);
                if (commentTabs.length > 0) {
                    setActiveTab(commentTabs[0]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getReferences = async (id, pid) => {
        try {
            const apiResponse = await getReferencesApi(id, pid);
            if (apiResponse.status === 200) {
                setReferencesData(apiResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Upload Evidence
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    // State For Handle Upload Button
    const [uploadFileLoading, setUploadFileLoading] = useState(false);
    const uploadEvidence = async (event) => {
        event.preventDefault();

        if (!selectedFile) {
            console.log('Please select a file to upload');
            return;
        }

        const maxFileSize = 10 * 1024 * 1024;
        if (selectedFile.size > maxFileSize) {
            toast.error('File size exceeds 10 MB. Please select a smaller file.');
            return;
        }

        const data = new FormData();
        data.append('file', selectedFile);
        data.append('id', idForFileUpload);
        data.append('pid', pid);

        try {
            setUploadFileLoading(true);
            const apiResponse = await uploadEvidenceApi(data);
            if (apiResponse.status === 200) {
                toast.success('File uploaded successfully');

                // Fetch the updated evidence data
                const updatedEvidenceResponse = await getCetificationEvidence(idForFileUpload, pid);
                setEvidenceFile(updatedEvidenceResponse.data); // Update the state with the new data
            } else if (apiResponse.status === 405) {
                toast.error(apiResponse.data);
            } else if (apiResponse.status === 415) {
                toast.error(apiResponse.data);
            } else if (apiResponse.status === 413) {
                toast.error(apiResponse.data);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setUploadFileLoading(false);
        }
    };

    //  Add Comment 
    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (inputMessage.trim()) {
            sendMessage(idForDeleteFile, pid, inputMessage, activeTab);
            setInputMessage('');
        }
    };

    // const sendMessage = async (idForDeleteFile, pid, comment, certificationName) => {
    //     try {
    //         const data = new FormData();
    //         data.append('id', idForDeleteFile);
    //         data.append('pid', pid);
    //         data.append('comment', comment);
    //         data.append('certificationName', certificationName);
    //         const apiResponse = await addCommentApi(data);
    //         console.log(idForDeleteFile, pid, comment, certificationName)
    //         if (apiResponse.status === 200) {
    //             toast.success("Message send successfully");
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };


    const sendMessage = async (idForDeleteFile, pid, comment, certificationName) => {
        try {
            const data = new FormData();
            data.append('id', idForDeleteFile);
            data.append('pid', pid);
            data.append('comment', comment);
            data.append('certificationName', certificationName);

            const apiResponse = await addCommentApi(data);
            if (apiResponse.status === 200) {
                toast.success("Message sent successfully");

                // Fetch the updated messages
                const updatedMessagesResponse = await getCetificationComments(idForDeleteFile, pid);
                if (updatedMessagesResponse.status === 200) {
                    setMessages(updatedMessagesResponse.data); // Update the messages state
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Toggle Mapped Points Api Logic
    const toggleMappedPointWithCCF = async (controlReference, mappedCompliance) => {

        setMappedComplianceName(mappedCompliance)
        setComplianceOffCanvas(!complianceOffCanvas);
        try {
            const apiResponse = await getMappedPointWithCCF(controlReference, mappedCompliance);
            if (apiResponse.status === 200) {
                setMappedPointsData(apiResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const [projectManager, setProjectManager] = useState('');
    const getProjectTaskDetailsCompliance = async (CLIENT_ID) => {
        try {
            const apiResponse = await getProjectTaskWithCCF(CLIENT_ID);
            if (apiResponse.status === 200) {
                setPid(apiResponse.data.id);
                setProjectManager(apiResponse.data.projectManager);
            }
        } catch (error) {
            console.log(error);
        }
    }


    // Todo Work All Compliance Set Karne Hn
    useEffect(() => {
        if (CLIENT_ID && CURRENT_ROLE) {

            getComplianceList();
            getProjectTaskDetailsCompliance(CLIENT_ID);

            if (otherComplianceTabName?.length) {
                const matchedCompliance = otherComplianceTabName.find(item => item.title === arrowNavTab);
                if (matchedCompliance) {
                    getOtherComplianceData(CLIENT_ID, matchedCompliance.title);
                }
            } else {
                console.log('otherComplianceTabName not available yet');
            }
          
        } else {
            console.log("Waiting for CLIENT_ID and CURRENT_ROLE...");
        }
    }, [CLIENT_ID, CURRENT_ROLE, arrowNavTab, otherComplianceTabName.length]);


    return (
        <>

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={'Project Task'} pageTitle={'Dashboard'} />
                    <Row>
                        <Col lg={12} className='pe-1 ps-1'>

                            <Nav pills className="nav nav-pills arrow-navtabs nav-success bg-light mb-2">

                                <NavItem className='me-2'>
                                    <NavLink
                                        style={{
                                            cursor: "pointer",
                                            padding: '.1rem .4rem',
                                            backgroundColor: arrowNavTab === defaultComplianceTabName.title ? '#0ab39c' : 'white'
                                        }}
                                        className={classnames({ active: arrowNavTab === defaultComplianceTabName.title })}
                                        onClick={() => { arrowNavToggle(defaultComplianceTabName.title); }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <h4 className="fs-12 mb-0" style={{ color: arrowNavTab === defaultComplianceTabName.title ? 'white' : 'black' }}>{defaultComplianceTabName.title}</h4>
                                            <div className="ms-3 avatar-xxs bg-white rounded-circle p-1 d-flex justify-content-center align-items-center">
                                                <img src={SERVER_URL_2 + defaultComplianceTabName.imageSrc} alt='...' style={{ width: '100%', height: '100%' }} onError={(e) => { e.target.src = slack }} />
                                            </div>
                                        </div>
                                    </NavLink>
                                </NavItem>

                                {
                                    otherComplianceTabName && otherComplianceTabName.map((item, index) => (
                                        <NavItem className='me-2' key={index}>
                                            <NavLink
                                                style={{
                                                    cursor: "pointer",
                                                    padding: '.1rem .4rem',
                                                    backgroundColor: arrowNavTab === item.title ? '#0ab39c' : 'white'
                                                }}
                                                className={classnames({ active: arrowNavTab === item.title })}
                                                onClick={() => { arrowNavToggle(item.title); }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <h4 className="fs-12 mb-0" style={{ color: arrowNavTab === item.title ? 'white' : 'black' }}>{item.title}</h4>
                                                    <div className="ms-3 avatar-xxs bg-white rounded-circle p-1 d-flex justify-content-center align-items-center">
                                                        <img src={SERVER_URL_2 + item.imageSrc} alt='...' style={{ width: '100%', height: '100%' }} onError={(e) => { e.target.src = slack }} />
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                    ))
                                }

                            </Nav>


                            <TabContent activeTab={arrowNavTab} className="text-muted" >

                                <CCFData
                                    defaultComplianceTabName={defaultComplianceTabName}
                                    toggleEvidence={toggleEvidence}
                                    toggleMappedPointWithCCF={toggleMappedPointWithCCF}
                                />

                                <OtherComplianceData
                                    otherComplianceData={otherComplianceData}
                                    setOtherComplianceData={setOtherComplianceData}
                                    otherComplianceTabName={otherComplianceTabName}
                                    toggleEvidence={toggleEvidence}
                                    pid={pid}
                                    activeFilter={activeFilter}
                                    setActiveFilter={setActiveFilter}
                                    originalOtherComplianceData={originalOtherComplianceData}
                                    currentOtherComplianceNameShow={currentOtherComplianceNameShow}
                                    pcid={pcid}
                                />
                            </TabContent>

                        </Col>
                    </Row>
                </Container>

                {/* Activity Canvas */}
                <ActivityCanvas
                    isRight={isRight}
                    toggleRightCanvas={toggleRightCanvas}
                    ccfNameTitle={ccfNameTitle}
                    topBorderTab={topBorderTab}
                    uploadEvidence={uploadEvidence}
                    uploadFileLoading={uploadFileLoading}
                    handleFileChange={handleFileChange}
                    evidenceFile={evidenceFile}
                    activityCommentsTab={activityCommentsTab}
                    activeTab={activeTab}
                    referencesData={referencesData}
                    messages={messages}
                    currentUser={currentUser}
                    handleSendMessage={handleSendMessage}
                    inputMessage={inputMessage}
                    handleInputChange={handleInputChange}
                    topBordertoggle={topBordertoggle}
                    toggle={toggle}
                    deleteEvidence={deleteEvidence}
                    downloadEvidence={downloadEvidence}
                    idForDeleteFile={idForDeleteFile}
                    pid={pid}
                    freezEvidence={freezEvidence}
                    projectManager={projectManager}
                    ccfStatusList={ccfStatusList}
                />

                {/* Compliance Canvas */}
                <ComplianceCanvas
                    complianceOffCanvas={complianceOffCanvas}
                    toggleComplianceOffCanvas={toggleComplianceOffCanvas}
                    mappedComplianceName={mappedComplianceName}
                    mappedPointsData={mappedPointsData}
                />
            </div>

        </>
    )
}

export default ProjectTask;