import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserDetails from "../hooks/useUserDetails";

const Navdata = () => {
  const navigate = useNavigate();
  const { CLIENT_ID, CURRENT_ROLE } = useUserDetails();
  const [role, setRole] = useState('ALL');

  useEffect(() => {
    if (CURRENT_ROLE) {
      setRole(CURRENT_ROLE);
    }
  }, [CURRENT_ROLE]);

  if (!role) {
    return [];
  }

  const [isDashboard, setIsDashboard] = useState(false);
  const [isCompanyDetails, setIsCompanyDetails] = useState(false);
  const [isUsersList, setIsUsersList] = useState(false);
  const [isAuditorsList, setIsAuditorsList] = useState(false);
  const [isProjects, setIsProjects] = useState(false);
  const [isManagePolicies, setIsManagePolicies] = useState(false);
  const [isEvidenceView, setIsEvidenceView] = useState(false);
  const [isManageAssets, setIsManageAssets] = useState(false);

  const [isCurrentState, setIsCurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (isCurrentState !== "Dashboard") setIsDashboard(false);
    if (isCurrentState !== "CompanyDetails") setIsCompanyDetails(false);
    if (isCurrentState !== "UsersList") setIsUsersList(false);
    if (isCurrentState !== "AuditorsList") setIsAuditorsList(false);
    if (isCurrentState !== "Projects") setIsProjects(false);
    if (isCurrentState !== "ManagePolicies") setIsManagePolicies(false);
    if (isCurrentState !== "EvidenceView") setIsEvidenceView(false);
    if (isCurrentState !== "ManageAssets") setIsManageAssets(false);
  }, [
    isDashboard,
    isCompanyDetails,
    isUsersList,
    isAuditorsList,
    isProjects,
    isManagePolicies,
    isEvidenceView,
    isManageAssets,
    navigate,
  ]);

  const rolePermissions = {
    ALL: [
      "dashboard",
      "companyDetails",
      "usersList",
      "auditorsList",
      "projects",
      "managePolicies",
      "evidenceView",
      "manageAssets",
    ],
    CLIENT_ADMIN: [
      "dashboard",
      "companyDetails",
      "usersList",
      "auditorsList",
      "projects",
      "managePolicies",
      "evidenceView",
      "manageAssets",
    ],
    AUDITOR: [
      "dashboard",
      "companyDetails",
      "projects",
      "managePolicies",
      "evidenceView",
      "manageAssets",
    ],
    CLIENT_USER: [
      "dashboard",
      "companyDetails",
      "projects",
      "managePolicies",
      "evidenceView",
      "manageAssets",
    ],
  };

  const menuItems = [
    { label: "Menu", isHeader: true, items: ["dashboard", "companyDetails"] },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "bx bxs-dashboard",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: (e) => {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIsCurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "companyDetails",
      label: "Company Details",
      icon: "bx bxs-detail",
      link: "/company-details",
      stateVariables: isCompanyDetails,
      click: (e) => {
        e.preventDefault();
        setIsCompanyDetails(!isCompanyDetails);
        setIsCurrentState("CompanyDetails");
        updateIconSidebar(e);
      },
    },
    { label: "Users", isHeader: true, items: ["usersList", "auditorsList"] },
    {
      id: "usersList",
      label: "Manage Users",
      icon: "bx bx-user-pin",
      link: "/users-list",
      stateVariables: isUsersList,
      click: (e) => {
        e.preventDefault();
        setIsUsersList(!isUsersList);
        setIsCurrentState("UsersList");
        updateIconSidebar(e);
      },
    },
    {
      id: "auditorsList",
      label: "Manage Auditors",
      icon: "bx bxs-user",
      link: "/auditors-list",
      stateVariables: isAuditorsList,
      click: (e) => {
        e.preventDefault();
        setIsAuditorsList(!isAuditorsList);
        setIsCurrentState("AuditorsList");
        updateIconSidebar(e);
      },
    },
    { label: "Project", isHeader: true, items: ["projects"] },
    {
      id: "projects",
      label: "Projects",
      icon: "bx bx-cabinet",
      link: "/projects",
      stateVariables: isProjects,
      click: (e) => {
        e.preventDefault();
        setIsProjects(!isProjects);
        setIsCurrentState("Projects");
        updateIconSidebar(e);
      },
    },
    { label: "Policies", isHeader: true, items: ["managePolicies"] },
    {
      id: "managePolicies",
      label: "Manage Policies",
      icon: "ri-honour-line",
      link: "/policies-list",
      stateVariables: isManagePolicies,
      click: (e) => {
        e.preventDefault();
        setIsManagePolicies(!isManagePolicies);
        setIsCurrentState("ManagePolicies");
        updateIconSidebar(e);
      },
    },
    {
      id: "evidenceView",
      label: "Evidence View",
      icon: "ri-file-shield-2-line",
      link: "/evidence-view",
      stateVariables: isEvidenceView,
      click: (e) => {
        e.preventDefault();
        setIsEvidenceView(!isEvidenceView);
        setIsCurrentState("EvidenceView");
        updateIconSidebar(e);
      },
    },
    { label: "Assets", isHeader: true, items: ["manageAssets"] },
    {
      id: "manageAssets",
      label: "Manage Assets",
      icon: "ri-stack-line",
      link: "/assets-list",
      stateVariables: isManageAssets,
      click: (e) => {
        e.preventDefault();
        setIsManageAssets(!isManageAssets);
        setIsCurrentState("ManageAssets");
        updateIconSidebar(e);
      },
    },
  ];

  const filteredMenuItems = menuItems.filter((item) => {
    if (item.isHeader) {
      return item.items.some((subItem) => rolePermissions[role]?.includes(subItem));
    } else {
      return rolePermissions[role]?.includes(item.id);
    }
  });

  return <React.Fragment>{filteredMenuItems}</React.Fragment>;
};

export default Navdata;