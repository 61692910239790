import { useSelector } from "react-redux";

const useUserDetails = () => {
    const { data } = useSelector(state => state.UserDetails);

    const CLIENT_ID = data?.clientCompanyId;
    const CURRENT_ROLE = data?.userProfiles?.[0]?.type;
    const COMPANY_NAME = data?.clientCompany;
    const SSO_ID = data?.ssoId;

    return { CLIENT_ID, CURRENT_ROLE, COMPANY_NAME, SSO_ID };
};

export default useUserDetails;
