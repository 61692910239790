import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import SupportTicketTable from '../../Components/Support/SupportTicketTable';
import { getSportTicketListAPI } from '../../apiConfig/apiCall';

export default function ManageSupport() {

    const [data, setData] = useState([]);

    const getSupportTicket = async () => {
        const apiResponse = await getSportTicketListAPI();
        if(apiResponse.status === 200){
            setData(apiResponse.data);
        }
    }

    useEffect(() => {
        getSupportTicket();
    }, [])


    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={'Manage Support'} pageTitle={"Dashboard"} />

                    <Row>
                        <Col lg={12}>
                            <SupportTicketTable data={data}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
