import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Tooltip } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Loader from '../../Components/Common/Loader';
import { dashboardCertificationCompliance, editProjectManagerApi, editQsaAuditorCCFApi, editQsaAuditorOtherComplianceApi, getAllUser, getCompanyDetails, getProjectDetail } from '../../apiConfig/apiCall';
import slack from '../../assets/images/brands/slack.png';
import useReactSelectCustomStyle from '../../hooks/useReactSelectCustomStyle';
import { ComplianceChartCard } from './ComplianceChartCard';
import MapUserModal from './MapUserModal';
import './project.css';
import useUserDetails from '../../hooks/useUserDetails';
import moment from 'moment';

const Projects = () => {

    const { CLIENT_ID, CURRENT_ROLE } = useUserDetails();

    const SERVER_URL = process.env.REACT_APP_API_IMAGE_URL;
    const SERVER_URL_2 = process.env.REACT_APP_API_IMAGE_URL_2;

    const role = CURRENT_ROLE;

    const [loading, setLoading] = useState(true);
    const [projectTaskTooltipOpen, setProjectTaskTooltipOpen] = useState(false);
    const [pid, setpid] = useState();
    const [projectStatus, setProjectStatus] = useState('Active');
    const [editProjectModal, setEditProjectModal] = useState(false);
    const [selectedQsa, setSelectedQsa] = useState(null);
    const [auditorsNameList, setAuditorsNameList] = useState([]);
    const [adminNameList, setAdminNameList] = useState([]);
    const [selectedProjectManager, setSelectedProjectManager] = useState(null);
    const [usersNameList, setUsersNameList] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [projectData, setProjectData] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [projectCertification, setProjectCertification] = useState([]);
    const [mapUserCCFModal, setMapUserCCFModal] = useState(false);
    const [editQsaModal, setEditQsaModal] = useState(false);
    const selectBoxCustomStyles = useReactSelectCustomStyle();

    // State For Hadle Loading Form
    const [submittingForm, setSubmittingForm] = useState(false);

    const toggleProjectTaskTooltip = () => {
        setProjectTaskTooltipOpen(!projectTaskTooltipOpen);
    };

    const toggleEditProjectModal = () => {
        setEditProjectModal(!editProjectModal);
    };

    const toggleMapUserCCFModal = () => setMapUserCCFModal(!mapUserCCFModal);
    const toggleEditQsaModal = () => setEditQsaModal(!editQsaModal);

    const QsaOptions = auditorsNameList.map(user => ({
        value: user.ssoId,
        label: `${user.firstName} ${user.lastName} (${user.ssoId})`
    }));


    const ProjectManagerOptions = [...adminNameList, ...usersNameList].map(user => ({
        value: user.ssoId,
        label: `${user.firstName} ${user.lastName} (${user.ssoId})`
    }));


    function handleSelectQsa(selectedQsa) {
        setSelectedQsa(selectedQsa);
    }

    function handleSelectProjectManager(selectedProjectManager) {
        setSelectedProjectManager(selectedProjectManager);
    }

    const getProjectDetailData = async (CLIENT_ID) => {
        try {
            const apiResponse = await getProjectDetail(CLIENT_ID);
            const companyNameData = await getCompanyDetails(CLIENT_ID);
            if (companyNameData.status === 200) {
                setCompanyData(companyNameData.data)
            }
            if (apiResponse.status === 200) {
                setpid(apiResponse.data.id);
                setProjectData(apiResponse.data);
            }
        } catch (error) {
            console.error("Error fetching project details:", error);
        }
    };

    const getChartsDataForCompliance = async (CLIENT_ID) => {
        try {
            const apiResponse = await dashboardCertificationCompliance(CLIENT_ID);
            if (apiResponse.status === 200) {
                setProjectCertification(apiResponse.data);
            }
        } catch (error) {
            console.error("Error fetching compliance data:", error);
        }
    };

    const getAuditors = async (auditorRole, CLIENT_ID) => {
        try {
            const auditorsNameListResponse = await getAllUser(auditorRole, CLIENT_ID);
            if (auditorsNameListResponse.status === 200) {
                setAuditorsNameList(auditorsNameListResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getClientAdmins = async (auditorRole, CLIENT_ID) => {
        try {
            const auditorsNameListResponse = await getAllUser(auditorRole, CLIENT_ID);
            if (auditorsNameListResponse.status === 200) {
                setAdminNameList(auditorsNameListResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getUsers = async (userRole, CLIENT_ID) => {
        try {
            const usersNameListResponse = await getAllUser(userRole, CLIENT_ID);
            if (usersNameListResponse.status === 200) {
                setUsersNameList(usersNameListResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [editQsaModalComplianceName, setEditQsaModalComplianceName] = useState('');
    const [idForSendQSAUpdate, setIdForSendQSAUpdate] = useState();
    const handleComplianceQsaAuditor = (compliance, id) => {
        toggleEditQsaModal(true);
        setEditQsaModalComplianceName(compliance);
        setIdForSendQSAUpdate(id);
    }

    const handleEditQsa = async (e) => {
        e.preventDefault();
        try {
            if (selectedQsa === null) {
                toast.error("Please select a qsa");
            } else {
                if (editQsaModalComplianceName === "CCF") {
                    const ccfQsaData = { ssoid: selectedQsa.value, pid: idForSendQSAUpdate }
                    setSubmittingForm(true);
                    const apiResponse = await editQsaAuditorCCFApi(ccfQsaData);
                    if (apiResponse.status === 200) {
                        toast.success(apiResponse.data);
                        setEditQsaModal(false);
                    }
                } else if (editQsaModalComplianceName !== "CCF") {
                    const otherComplianceQsaData = { ssoid: selectedQsa.value, pcid: idForSendQSAUpdate }
                    setSubmittingForm(true);
                    const apiResponse = await editQsaAuditorOtherComplianceApi(otherComplianceQsaData);
                    if (apiResponse.status === 200) {
                        toast.success(apiResponse.data);
                        setEditQsaModal(false);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setSubmittingForm(false);
        }
    };

    const handleEditProjectManager = async (event) => {
        try {
            event.preventDefault();
            if (selectedProjectManager === null) {
                toast.error("Please select a project manager");
            } else {
                setSubmittingForm(true);
                const data = { ssoid: selectedProjectManager.value, pid }
                const apiResponse = await editProjectManagerApi(data);
                if (apiResponse.status === 200) {
                    toast.success(apiResponse.data);
                    setEditProjectModal(false);
                }
            }
        } catch (error) {
            console.error('Error sending data:', error);
            toast.error('An error occurred while edit project manager');
        } finally {
            setSubmittingForm(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await getProjectDetailData(CLIENT_ID);
            await getChartsDataForCompliance(CLIENT_ID);
            await getAuditors('AUDITOR', CLIENT_ID);
            await getUsers('CLIENT_USER', CLIENT_ID);
            await getClientAdmins('CLIENT_ADMIN', CLIENT_ID);
            setLoading(false);
        };
        if (CLIENT_ID || CURRENT_ROLE) {
            fetchData();
        }
    }, [CLIENT_ID, editQsaModal, editProjectModal]);

    if (loading) return <Loader />

    return (
        <>

            <div className="page-content">
                <Container fluid>

                    <BreadCrumb title={'Projects'} pageTitle={'Dashboard'} />

                    <Row>
                        <Col lg={12} className='pe-1 ps-1'>
                            <Card className='mb-2'>
                                <div>
                                    <CardBody className="pb-0 px-4">
                                        <Row className="mb-3">
                                            <div className="col-md">
                                                <Row className="align-items-center g-3">
                                                    <div className="col-md-auto">
                                                        <div className="avatar-md">
                                                            <div className="avatar-title bg-white rounded-circle">
                                                                <img src={SERVER_URL + companyData.clientDetails.file} alt="" className="avatar-md rounded-circle" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                                <div className='d-flex align-items-center'>
                                                                    <h4 className="fw-bold me-2 mb-0">{companyData.clientDetails.companyName}</h4>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <h4 className="mb-0">Manage CCF</h4>
                                                                <div className='d-flex align-items-center mb-2'>

                                                                    {
                                                                        role === "CLIENT_ADMIN" && (
                                                                            <Button
                                                                                color='primary'
                                                                                className='me-2'
                                                                                size='sm'
                                                                                onClick={toggleMapUserCCFModal}
                                                                            >
                                                                                Map User
                                                                            </Button>
                                                                        )
                                                                    }

                                                                    {/* <UncontrolledDropdown>
                                                                                <DropdownToggle tag="button" className={`btn btn-sm ${projectStatus === "Active" ? 'btn-secondary' : projectStatus === "Completed" ? 'btn-success' : 'btn-secondary'}`} id="dropdownMenuButton">
                                                                                    Status : {projectStatus} <i className="mdi mdi-chevron-down"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem onClick={() => setProjectStatus("Active")}>Active</DropdownItem>
                                                                                    <DropdownItem onClick={() => setProjectStatus("Completed")}>Completed</DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown> */}
                                                                </div>
                                                            </div>

                                                            <Row>

                                                                <Col lg={4}>
                                                                    <div className='d-flex align-items-center'><i className="bx bx-user-circle me-1"></i> Project Manager : <span className="fw-600 ms-1">
                                                                        {projectData.projectManager}
                                                                    </span>
                                                                        {
                                                                            role === "CLIENT_ADMIN" && (
                                                                                <div
                                                                                    className='ms-2 cursor-pointer'
                                                                                    onClick={toggleEditProjectModal}
                                                                                >
                                                                                    <i className="ri-edit-2-fill fs-4"></i>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </Col>

                                                                {/* <Col lg={4}>
                                                                        <div className='d-flex align-items-center'><i className="bx bx-user-pin me-1"></i> QSA(Auditor) : <span className="fw-600 ms-1">
                                                                            {projectData.dba}
                                                                        </span></div>
                                                                    </Col> */}

                                                                <Col lg={4}>
                                                                    <div className='d-flex align-items-center'><i className="bx bxs-calendar me-1"></i>Due Date : <span className="fw-600 ms-1">{projectData.dueDate}</span></div>
                                                                </Col>
                                                                <Col lg={8}>
                                                                    <div className='d-flex align-items-center mt-2'><i className="bx bxs-objects-vertical-bottom me-1"></i> Manage CCF :
                                                                        <div className='d-flex align-items-center ms-2'>
                                                                            <div className="ccf-action me-2" id='projectTaskTooltip'>
                                                                                <Link to={`/project-task/${projectData.id}?CCF`}>
                                                                                    <Button color='success' style={{ fontSize: '.7rem', padding: ".2rem .5rem" }}>View Task & Evidence</Button>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Tooltip
                                                                    placement="top"
                                                                    isOpen={projectTaskTooltipOpen}
                                                                    target="projectTaskTooltip"
                                                                    toggle={toggleProjectTaskTooltip}>
                                                                    Project Task | Evidence Upload
                                                                </Tooltip>

                                                            </Row>

                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {
                            projectCertification
                                .filter(data => data.status !== "INACTIVE")
                                .map((data, index) => (
                                    <Col key={index} lg={3} sm={6} className="project-card pe-1 ps-1">
                                        <Card className="card-height-100">
                                            <CardBody className='pb-0'>
                                                <div className="d-flex flex-column h-100">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <div className='mb-2' style={{ width: '4rem' }}>
                                                                <img
                                                                    src={SERVER_URL_2 + data.image}
                                                                    onError={(e) => { e.target.src = slack }}
                                                                    className='img-fluid' alt="..." />
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="d-flex gap-1 align-items-center">
                                                                <Link to={`/project-task/${projectData.id}?${data.name}`} className='cursor-pointer'>
                                                                    <i className='ri-eye-fill'></i>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 d-flex justify-content-between">
                                                            <div>
                                                                <h5 className="mb-1 fs-11">QSA(Auditor)</h5>
                                                                <p className="mb-0 fs-11">
                                                                    {data.auditorName}
                                                                </p>
                                                            </div>
                                                            {
                                                                role === "CLIENT_ADMIN" && (
                                                                    <div className="ms-2 cursor-pointer" onClick={() => handleComplianceQsaAuditor(data.name, data.id)}>
                                                                        <i className="ri-edit-2-fill fs-4"></i>
                                                                    </div>
                                                                )}

                                                        </div>
                                                    </div>
                                                    <ComplianceChartCard name={data.name} total={data.total} pending={data.pending} completed={data.completed} rejected={data.rejected} />
                                                </div>
                                            </CardBody>
                                            <div className="card-footer bg-transparent border-top-dashed py-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1">
                                                        <div className="avatar-group d-flex">
                                                            <div className='d-flex align-items-center'>
                                                                <h6 className="mb-0 fs-10 me-1">Due Date : </h6>
                                                                <h6 className="mb-0 fs-10 me-1">{moment(data.other).format('DD-MMM-YYYY')}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                ))
                        }
                    </Row>

                </Container>


                {/* Edit Project Manager Modal */}
                <Modal isOpen={editProjectModal} toggle={toggleEditProjectModal}>
                    <ModalHeader toggle={toggleEditProjectModal}>Edit Project Manager</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row className="gy-2">
                                <Col lg={12}>
                                    <div>
                                        <Label htmlFor="sampleInput" className="form-label">Project Manager</Label>
                                        <Select
                                            value={selectedProjectManager}
                                            onChange={handleSelectProjectManager}
                                            options={ProjectManagerOptions}
                                            styles={selectBoxCustomStyles}
                                        />
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    {
                                        submittingForm ? <Button color="primary" type='button' disabled>
                                            <Spinner size="sm"> Loading... </Spinner> </Button> : <Button color="primary" type='submit' onClick={handleEditProjectManager}> Update </Button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>

                {/* Map User Modal */}
                <MapUserModal
                    mapUserCCFModal={mapUserCCFModal}
                    toggleMapUserCCFModal={toggleMapUserCCFModal}
                    pid={pid}
                    CLIENT_ID={CLIENT_ID}
                />

                {/* Edit QSA Modal */}
                <Modal isOpen={editQsaModal} toggle={toggleEditQsaModal}>
                    <ModalHeader toggle={toggleEditQsaModal}>Edit QSA For {editQsaModalComplianceName}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row className="gy-2">
                                <Col lg={12}>
                                    <div>
                                        <Label htmlFor="sampleInput" className="form-label">QSA (Auditor)</Label>
                                        <Select
                                            value={selectedQsa}
                                            onChange={handleSelectQsa}
                                            options={QsaOptions}
                                            styles={selectBoxCustomStyles}
                                        />
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    {
                                        submittingForm ? <Button color="primary" type='button' disabled>
                                            <Spinner size="sm"> Loading... </Spinner> </Button> : <Button color="primary" type='submit' onClick={handleEditQsa}> Update </Button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            </div >
        </>
    )
}

export default Projects;