import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import userImg from "../../assets/images/users/avatar-3.jpg";

const ActiveUsers = ({ title, activeUsersData }) => {

    const IMAGE_URL = process.env.REACT_APP_API_IMAGE_URL;

    return (
        <>
            <Col xl={5} lg={5} className='pe-1 ps-1'>
                <Card className='dashboardTableTwo'>
                    <CardHeader className="align-items-center d-flex py-2 position-relative" style={{ zIndex: 2 }}>
                        <h4 className="card-title mb-0 flex-grow-1">{title}</h4>
                    </CardHeader>

                    <CardBody>
                        <div className="table-responsive table-card">
                            <div className="scrollTable">
                                {activeUsersData.length === 0 ? (
                                    <div className="text-center my-3">
                                        <p>No data available</p>
                                    </div>
                                ) : (
                                    <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                        <thead className="text-muted table-light" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                            <tr>
                                                <th scope="col" className='py-1 fs-12'>Users Name</th>
                                                <th scope="col" className='py-1 fs-12'>Pending Items</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(activeUsersData || []).map((item, key) => (<tr key={key}>
                                                <td className='py-0 fs-12'>
                                                {
                                                        !item.profileImage ?
                                                            <img
                                                                src={userImg}
                                                                className="avatar-xxs rounded-circle me-1 material-shadow"
                                                                alt="..."
                                                            /> :
                                                            <img
                                                                src={IMAGE_URL + item.profileImage}
                                                                className="avatar-xxs rounded-circle me-1 material-shadow"
                                                                alt="..."
                                                                onError={(e) => { e.target.src = userImg }}
                                                            />
                                                    }
                                                    {item.firstName} {item.lastName}
                                                </td>
                                                <td className='fs-12'>
                                                    <div className="d-flex align-items-center">
                                                        <div style={{width: '15%', marginRight: '1rem'}}>{item.projectTaskCount.pending} / {item.projectTaskCount.total}</div>
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                height: "1.5rem",
                                                                width: "1.5rem",
                                                                borderRadius: "50%",
                                                                background: `conic-gradient(#012543 ${item.projectTaskCount.percent * 36}deg, #e0e0e0 0deg)`,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    height: "1rem",
                                                                    width: "1rem",
                                                                    borderRadius: "50%",
                                                                    background: "#fff",
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default ActiveUsers;