import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getLoggedInUserAPI } from "../apiConfig/apiCall";
import Loader from '../Components/Common/Loader';

export const AuthProtected = ({ children }) => {
  const REDIRECT_LOGIN_URL = process.env.REACT_APP_REDIRECT_LOGIN_URL;

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);

  const getLoggedInUserDetails = async () => {
    try {
      const apiResponse = await getLoggedInUserAPI();
      if (apiResponse.status === 200) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
      toast.error("Error fetching user details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLoggedInUserDetails();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return window.location.href = REDIRECT_LOGIN_URL;
  }

  return <>{children}</>;
};
