import { commonRequest } from "./axiosConfig";

/**
 * @DESC Authentication Api Route & Other (User Details, Client Company Detail, Notification, Support Ticket);
 */
const AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
const BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const loginUser = async (data) => {
    return commonRequest("POST", `${AUTH_URL}/auth/login`, data);
};
export const verifyOtp = async (data) => {
    return commonRequest("POST", `${AUTH_URL}/auth/verify-otp`, data);
};
export const getLoggedInUserAPI = async () => {
    return await commonRequest("GET", `${AUTH_URL}/user/get-user`);
}
export const forgotPassword = async (data) => {
    return commonRequest("PUT", `${AUTH_URL}/auth/reset-password`, data);
};
export const logoutAPI = async () => {
    return await commonRequest("POST", `${AUTH_URL}/auth/logout`);
}

export const logoutAllAPI = async () => {
    return await commonRequest("POST", `${AUTH_URL}/auth/logout-all`);
}

export const addUser = async (data, role) => {
    return await commonRequest("POST", `${AUTH_URL}/user/add-user/${role}`, data);
}

export const getAllUser = async (USER_ROLE, CLIENT_ID) => {
    return await commonRequest("GET", `${AUTH_URL}/user/user-list/${USER_ROLE}/${CLIENT_ID}`);
}

export const deleteUser = async (USER_NAME) => {
    return await commonRequest("DELETE", `${AUTH_URL}/user/delete-user/${USER_NAME}`);
}

export const getSingleUser = async (username) => {
    return await commonRequest("GET", `${AUTH_URL}/user/get-user/${username}`);
}

export const editUser = async (data, ssoId) => {
    return await commonRequest("PUT", `${AUTH_URL}/user/edit-user/${ssoId}`, data);
}


export const changePasswordApi = async (data) => {
    return await commonRequest("PUT", `${AUTH_URL}/user/update-password`, data);
}

/**
 * @DESC Dashboard Api Start
 */
export const dashboardTotalUsersAndAuditors = async (role) => {
    return await commonRequest("GET", `${AUTH_URL}/user/total-users/${role}`);
}

export const dashboardTotalPolicies = async (CLIENT_ID) => {
    return await commonRequest("GET", `${BASE_URL}/policies/total-policies/${CLIENT_ID}`);
}

export const dashboardTotalAssets = async (CLIENT_ID) => {
    return await commonRequest("GET", `${BASE_URL}/assets/total-assets/${CLIENT_ID}`);
}

export const dashboardOverallCompliance = async (CLIENT_ID) => {
    return await commonRequest("GET", `${BASE_URL}/project/overall-compliance-count/${CLIENT_ID}`);
}

export const dashboardEvidenceStatus = async (CLIENT_ID) => {
    return await commonRequest("GET", `${BASE_URL}/project/all-evidence-status-count/${CLIENT_ID}`);
}

export const dashboardCertificationCompliance = async (CLIENT_ID) => {
    return await commonRequest("GET", `${BASE_URL}/project/all-certification-compliance-count/${CLIENT_ID}`);
}

export const dashboardDepartmentCompliance = async (CLIENT_ID) => {
    return await commonRequest("GET", `${BASE_URL}/project/all-department-compliance-count/${CLIENT_ID}`);
}

export const dashboardActiveCompliance = async (CLIENT_ID) => {
    return await commonRequest("GET", `${BASE_URL}/project/all-active-compliance/${CLIENT_ID}`);
}

export const dashboardActiveUsers = async (CLIENT_ID) => {
    return await commonRequest("GET", `${BASE_URL}/project/task-user-list/${CLIENT_ID}`);
}
/**
 * @DESC Dashboard Api End
 */

/**
 * @DESC Company Details Api Start
 */
export const getCompanyDetails = async (CLIENT_ID) => {
    return await commonRequest("GET", `${AUTH_URL}/clients/company-details/${CLIENT_ID}`);
}

export const editCompanyContact = async (id, data) => {
    return await commonRequest("PUT", `${AUTH_URL}/clients/edit-company-contact/${id}`, data);
}

export const editCompanyCEO = async (id, data) => {
    return await commonRequest("PUT", `${AUTH_URL}/clients/edit-company-adminceo/${id}`, data);
}

export const editCompanyPaymentInfo = async (id, data) => {
    return await commonRequest("PUT", `${AUTH_URL}/clients/edit-company-payment/${id}`, data);
}

export const editCompanyMerchantBusiness = async (id, data) => {
    return await commonRequest("PUT", `${AUTH_URL}/clients/edit-company-merchant-business/${id}`, data);
}

export const editCompanyServiceProvider = async (id, data) => {
    return await commonRequest("PUT", `${AUTH_URL}/clients/edit-company-services/${id}`, data);
}

export const uploadCompanyLogoApi = async (CLIENT_ID, data) => {
    return await commonRequest("PUT", `${AUTH_URL}/clients/upload-company-logo/${CLIENT_ID}`, data, {
        'Content-Type': 'multipart/form-data'
    });
}
/**
 * @DESC Company Details Api End
 */



/**
 * @DESC Project Page Api Start
 */
export const getProjectDetail = async (CLIENT_ID) => {
    return await commonRequest("GET", `${BASE_URL}/project/get-project/${CLIENT_ID}`);
}

// Project Task Api
export const getComplianceListApi = async (clientCompanyId) => {
    return await commonRequest("GET", `${BASE_URL}/project/all-compliance-name/${clientCompanyId}`);
}
export const getProjectTaskWithCCF = async (CLIENT_ID) => {
    return await commonRequest("GET", `${BASE_URL}/project/get-project-with-ccf/${CLIENT_ID}`);
}
export const getProjectTaskWithOtherCertification = async (CLIENT_ID, certificationName) => {
    return await commonRequest("GET", `${BASE_URL}/project/get-project-with-certification/${CLIENT_ID}/${certificationName}`);
}
export const getMappedPointWithCCF = async (controlReference, certification) => {
    return await commonRequest("GET", `${BASE_URL}/ccf/get-ccf-mapped-cvp/${controlReference}/${certification}`);
}
export const getCetificationEvidence = async (id, pid) => {
    return await commonRequest("GET", `${BASE_URL}/ra/get-ccf-evidences/${id}/${pid}`);
}
export const deleteEvidenceApi = async (data) => {
    return await commonRequest("DELETE", `${BASE_URL}/ra/delete-approach-file`, data);
}
export const freezEvidenceAPI = async (data) => {
    return await commonRequest("PUT", `${BASE_URL}/ra/update-status-evidence`, data);
}
export const downloadEvidenceApi = async (id, pid, filename, responseType) => {
    return await commonRequest("GET", `${BASE_URL}/ra/download-approach-file/${id}/${pid}?filename=${filename}`, {}, {}, responseType);
};
export const uploadEvidenceApi = async (data) => {
    return await commonRequest("POST", `${BASE_URL}/ra/upload-approach-file`, data, {
        'Content-Type': 'multipart/form-data'
    });
};
export const getActivityComments = async (id, pid) => {
    return await commonRequest("GET", `${BASE_URL}/ra/get-all-certification-comments/${id}/${pid}`);
}
export const getCetificationComments = async (id, pid) => {
    return await commonRequest("GET", `${BASE_URL}/ra/get-all-certification-comments/${id}/${pid}`);
}
export const addCommentApi = async (data) => {
    return await commonRequest("PUT", `${BASE_URL}/ra/add-user-comment`, data);
}
export const getReferencesApi = async (id, pid) => {
    return await commonRequest("GET", `${BASE_URL}/ra/get-ccf-references/${id}/${pid}`);
}

export const downloadAllEvidenceAPI = async (id, ccf, responseType) => {
    return await commonRequest("GET", `${BASE_URL}/ra/download-files-zip/${id}/${ccf}`, {}, {}, responseType);
}

export const getCCFControlsApi = async () => {
    return await commonRequest("GET", `${BASE_URL}/ccf/get-ccf-filter-list`);
}
export const getProjectTaskWithCCFFilterByControlApi = async (CLIENT_ID, controlName, controlValue) => {
    return await commonRequest("GET", `${BASE_URL}/project/get-project-with-ccf/${CLIENT_ID}/${controlName}/${controlValue}`);
}
export const getProjectTaskWithOtherCertificationFilterByControlApi = async (CLIENT_ID, certificationName, controlName, controlValue) => {
    return await commonRequest("GET", `${BASE_URL}/project/get-project-with-certification/${CLIENT_ID}/${certificationName}/${controlName}/${controlValue}`);
}

// Manage Users Api
export const uploadUserProfileImageApi = async (SSO_ID, data) => {
    return await commonRequest("PUT", `${AUTH_URL}/user/upload-profile-image/${SSO_ID}`, data, {
        'Content-Type': 'multipart/form-data'
    });
}


// Manage Policy Api
export const getPoliciesApi = async (CLIENT_ID) => {
    return await commonRequest("GET", `${BASE_URL}/policies/company-policies-list/${CLIENT_ID}`);
}
export const downloadPoliciesApi = async (POLICY_ID, responseType) => {
    return await commonRequest("GET", `${BASE_URL}/policies/download-company-policy/${POLICY_ID}`, {}, {}, responseType);
}

export const addPolicyApi = async (data) => {
    return await commonRequest("POST", `${BASE_URL}/policies/upload-company-policy`, data, {
        'Content-Type': 'multipart/form-data'
    });
}
export const deletePoliciesApi = async (POLICY_ID) => {
    return await commonRequest("DELETE", `${BASE_URL}/policies/delete-company-policy/${POLICY_ID}`);
}

// Manage Assets Api
export const getAssetsApi = async () => {
    return await commonRequest("GET", `${BASE_URL}/assets/company-assets-list`);
}
export const addAssetApi = async (data) => {
    return await commonRequest("POST", `${BASE_URL}/assets/add-company-asset`, data);
}
export const addAssetByCSVApi = async (data) => {
    return await commonRequest("POST", `${BASE_URL}/assets/upload-company-assets-by-csv`, data, {
        'Content-Type': 'multipart/form-data'
    });
}
export const deleteAssetApi = async (ASSET_ID) => {
    return await commonRequest("DELETE", `${BASE_URL}/assets/delete-company-asset/${ASSET_ID}`);
}
export const getSingleAssetApi = async (ASSET_ID) => {
    return await commonRequest("GET", `${BASE_URL}/assets/get-company-asset/${ASSET_ID}`);
}
export const editAssetApi = async (ASSET_ID, data) => {
    return await commonRequest("PUT", `${BASE_URL}/assets/edit-company-asset/${ASSET_ID}`, data);
}

// Map User To CCF API
export const mapUserWithCCFApi = async (data) => {
    return await commonRequest("PUT", `${BASE_URL}/ra/update-ra-user`, data);
}

export const getCCFStatusAPI = async (id, pid) => {
    return await commonRequest("GET", `${BASE_URL}/ra/get-ccf-status/${id}/${pid}`);
}

export const updateCCFStatusAPI = async (data) => {
    return await commonRequest("PUT", `${BASE_URL}/ra/update-approach-status-certification`, data);
}




// Edit QSA(Auditor) CCF API
export const editQsaAuditorCCFApi = async (data) => {
    return await commonRequest("PUT", `${BASE_URL}/project/assign-ccf-auditor`, data);
}

// Edit QSA(Auditor) Other COmpliance API
export const editQsaAuditorOtherComplianceApi = async (data) => {
    return await commonRequest("PUT", `${BASE_URL}/pc/assign-certification-auditor`, data);
}

// Edit Project Manager API
export const editProjectManagerApi = async (data) => {
    return await commonRequest("PUT", `${BASE_URL}/project/assign-project-manager`, data);
}



// Applicable
export const updateApplicableChangeAPI = async (data) => {
    return await commonRequest("PUT", `${BASE_URL}/cra/update-cra-applicable`, data);
}

// Notification API
export const getNotificationsAPI = async (DATA) => {
    return await commonRequest("POST", `${AUTH_URL}/notifications/notifications-list`, DATA);
}
export const editNotificationAPI = async (DATA) => {
    return await commonRequest("PUT", `${AUTH_URL}/notifications/edit-notification`, DATA);
}

// Evidence View API
export const getEvidenceViewAPI = async (FILTER_DATE) => {
    return await commonRequest("GET", `${BASE_URL}/evidence/evidence-list/${FILTER_DATE}`);
}
export const downloadEvidenceViewAPI = async (pid, filename, responseType) => {
    return await commonRequest("GET", `${BASE_URL}/ra/download-approach-file/${pid}?filename=${filename}`, {}, {}, responseType);
};
export const downloadAllEvidenceViewAPI = async (pid, filenames, responseType) => {
    return await commonRequest("GET", `${BASE_URL}/ra/download-approach-files/${pid}?filenames=${filenames}`, {}, {}, responseType);
};

// PCI Evidence API
export const getPCIEvidenceAPI = async (PID, CRA_ID) => {
    return await commonRequest("GET", `${BASE_URL}/ra/get-certification-evidences/${PID}/${CRA_ID}`);
}
export const downloadAllPCIEvidenceAPI = async (PID, CRA_ID, responseType) => {
    return await commonRequest("GET", `${BASE_URL}/ra/download-approach-files/${PID}/${CRA_ID}`, {}, {}, responseType);
}


// Support Tickets API
export const getSportTicketListAPI = async () => {
    return await commonRequest("GET", `${AUTH_URL}/support/support-ticket-list`);
}
export const getSingleSportTicketListAPI = async (ID) => {
    return await commonRequest("GET", `${AUTH_URL}/support/get-support-ticket/${ID}`);
}
export const addSportTicketAPI = async (DATA) => {
    return await commonRequest("POST", `${AUTH_URL}/support/add-support-ticket`, DATA);
}
export const deleteSportTicketAPI = async (ID) => {
    return await commonRequest("DELETE", `${AUTH_URL}/support/delete-support-ticket/${ID}`);
}
export const addSupportCommentAPI = async (DATA) => {
    return await commonRequest("PUT", `${AUTH_URL}/support/comments-support-ticket`, DATA, {
        'Content-Type': 'multipart/form-data'
    });
}
export const reOpenSupportTicketAPI = async (ID) => {
    return await commonRequest("PUT", `${AUTH_URL}/support/reopen-support-ticket/${ID}`);
}

// Manage License API
export const getClientLicenseListAPI = async (CLIENT_ID) => {
    return await commonRequest("GET", `${AUTH_URL}/license/client-license-list/${CLIENT_ID}`);
} 