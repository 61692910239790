import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Spinner } from "reactstrap";

export const OverallComplianceChart = ({ data }) => {
  const [series, setSeries] = useState([0, 0, 0]);
  useEffect(() => {
    if (data && data.totalCCF !== undefined) {
      setSeries([data.totalCCF, data.completedCCF, data.pendingCCF]);
    }
  }, [data]);
  const options = {
    labels: ["Total", "Completed", "Pending"],
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        size: 100,
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: "90%",
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      lineCap: "round",
      width: 0,
    },
  };

  return (
    <>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        height={165}
        data-colors='["--vz-success", "--vz-primary", "--vz-warning", "--vz-danger"]'
        type="donut"
        className="apex-charts"
      />
    </>
  );
};

export const EvidenceStatusChart = ({ data }) => {
  const [series, setSeries] = useState([0, 0, 0]);
  useEffect(() => {
    if (data && data.total !== undefined) {
      setSeries([data.total, data.completed, data.rejected, data.pending]);
    }
  }, [data]);
  var options = {
    labels: ["Total", "Completed", "Rejected", "Pending"],
    chart: {
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient',
    },
    legend: {
      position: 'bottom',
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex]
      }
    },
  };
  return (
    <>
      <ReactApexChart dir="ltr"
        className="apex-charts"
        series={series}
        options={options}
        type="donut"
        height={295}
      />
    </>
  )
}

export const CertificationComplianceChart = ({ data }) => {
  const [chartHeight, setChartHeight] = useState(getChartHeight());

  function getChartHeight() {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1200) {
      return 175;
    } else if (screenWidth > 992) {
      return 240;
    } else if (screenWidth > 768) {
      return 175;
    } else {
      return 300;
    }
  }


  const series = [
    {
      name: "Total",
      data: data.map((item) => item.total),
    },
    {
      name: "Pending",
      data: data.map((item) => item.pending),
    },
    {
      name: "Completed",
      data: data.map((item) => item.completed),
    },
    {
      name: "Rejected",
      data: data.map((item) => item.rejected),
    },
  ];

  const categories = data.map((item) => item.name);

  // const options = {
  //   chart: {
  //     type: "bar",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       columnWidth: "40%",
  //       endingShape: "rounded",
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     show: true,
  //     width: 2,
  //     colors: ["transparent"],
  //   },
  //   colors: ["#405189", "#FFA500", "#0ab39c", '#FF0000'],
  //   xaxis: {
  //     categories: categories,
  //   },
  //   grid: {
  //     borderColor: "#f1f1f1",
  //   },
  //   fill: {
  //     opacity: 1,
  //   },
  //   tooltip: {
  //     y: {
  //       formatter: function (val) {
  //         return val;
  //       },
  //     },
  //   },
  //   yaxis: {
  //     min:5, 
  //   },
  // };

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#405189", "#FFA500", "#0ab39c", '#FF0000'],
    xaxis: {
      categories: categories,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    yaxis: {
      min: 0,
      max: Math.max(...data.map(item => item.total)) * 0.1,
      labels: {
        formatter: function (value) {
          return value.toFixed(0);
        },
      },
    },
  };


  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="bar"
      height={chartHeight}
      width={500}
    />
  );
};