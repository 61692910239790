import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import PolicyTable from './PolicyTable'
import Cookies from 'js-cookie';
import { getPoliciesApi } from '../../apiConfig/apiCall';
import DataLoading from '../../Components/Common/DataLoading';
import useUserDetails from '../../hooks/useUserDetails';

const PoliciesList = () => {

    const { CLIENT_ID, CURRENT_ROLE } = useUserDetails();

    const [loading, setLoading] = useState(true);

    // Get Policies List Logic
    const [policiesData, setPoliciesData] = useState([]);
    const getPolicies = async (CLIENT_ID) => {
        try {
            const apiResponse = await getPoliciesApi(CLIENT_ID);
            if (apiResponse.status === 200) {
                setPoliciesData(apiResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await getPolicies(CLIENT_ID);
            setLoading(false);
        };
        if (CLIENT_ID) {
            fetchData();
        }
    }, [CLIENT_ID]);

    return (
        <>
            {loading ? (
                <DataLoading />
            ) : (
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title={'Policies List'} pageTitle={'Dashboard'} />
                        <Row>
                            <Col lg={12}>
                                <PolicyTable policiesData={policiesData} getPolicies={getPolicies} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </>
    )
}

export default PoliciesList