import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

//import images
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { changePasswordApi, logoutAllAPI, logoutAPI } from '../../apiConfig/apiCall';
import demoUserImg from "../../assets/images/users/user-dummy-img.jpg";
import { getLoggedInUserDetails } from '../../slices/UserDeatils/thunk';
import Error from './Error';
import Loader from './Loader';

const ProfileDropdown = () => {

    const IMAGE_URL = process.env.REACT_APP_API_IMAGE_URL;
    const REDIRECT_LOGIN_URL = process.env.REACT_APP_REDIRECT_LOGIN_URL;

    const dispatch = useDispatch();
    const { isLoading, isError, data: userDeatils } = useSelector(state => state.UserDetails);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const navigate = useNavigate();
    const handleLogout = async () => {
        const response = await logoutAPI();
        if (response.status === 200) {
            toast.success(response.data);
            localStorage.clear();
            window.location.href = REDIRECT_LOGIN_URL;
        } else {
            toast.error(response.data);
        }
    }

    const handleLogoutAll = async () => {
        const response = await logoutAllAPI();
        if (response.status === 200) {
            toast.success(response.data);
            localStorage.clear();
            window.location.href = REDIRECT_LOGIN_URL;
        } else {
            toast.error(response.data);
        }
    }

    // Change Password Logic
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const toggleChangePasswordModal = () => setChangePasswordModal(!changePasswordModal);

    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (value.length <= 15) { // Allow only up to 15 characters
            setFormData({ ...formData, [name]: value });
        }
    };


    const handleChangePassword = async (event) => {
        event.preventDefault();
        try {
            const { currentPassword, newPassword } = formData;
            if (currentPassword === "") {
                toast.error("Current Password is required");
                return;
            } else if (newPassword === "") {
                toast.error("New Password is required");
                return;
            } else {
                const data = { currentPassword, newPassword }
                const apiResponse = await changePasswordApi(data);
                if (apiResponse.status === 200) {
                    toast.success(apiResponse.data);
                    setChangePasswordModal(false);
                    localStorage.clear();
                    window.location.href = REDIRECT_LOGIN_URL;
                }
                else if (apiResponse.status === 400) {
                    toast.error(apiResponse.data);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        dispatch(getLoggedInUserDetails());
    }, [dispatch]);

    return (
        <>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" alt="...."
                            src={IMAGE_URL + userDeatils.profileImage}
                            onError={(e) => { e.target.src = demoUserImg; }}
                        />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userDeatils.firstName + " " + userDeatils.lastName}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userDeatils.designation}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {userDeatils.firstName + " " + userDeatils.lastName}!</h6>
                    <DropdownItem className='p-0'>
                        <Link to="/dashboard" className="dropdown-item">
                            <i className="mdi mdi-view-grid text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Dashboard</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to="#" className="dropdown-item" onClick={toggleChangePasswordModal}>
                            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Change Password</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to="/support" className="dropdown-item">
                            <i
                                className="mdi mdi-help-box text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle">Support</span>
                        </Link>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <DropdownItem className='p-0'>
                        <div className="dropdown-item" onClick={handleLogout}>
                            <i
                                className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle" data-key="t-logout">Logout</span>
                        </div>
                    </DropdownItem>

                    <DropdownItem className='p-0'>
                        <div className="dropdown-item" onClick={handleLogoutAll}>
                            <i
                                className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle" data-key="t-logout">Logout All</span>
                        </div>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>

            {/* Chnage Password Modal */}
            <Modal isOpen={changePasswordModal} toggle={toggleChangePasswordModal}>
                <ModalHeader toggle={toggleChangePasswordModal}>Change Password</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleChangePassword}>
                        <Row className="gy-4">
                            <Col lg={12}>
                                <div>
                                    <Label htmlFor="currentPassword" className="form-label">Current Password</Label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        id="currentPassword"
                                        name="currentPassword"
                                        placeholder="Enter current password"
                                        value={formData.currentPassword}
                                        onChange={handleInputChange}
                                        autoComplete='off'
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div>
                                    <Label htmlFor="newPassword" className="form-label">New Password</Label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        id="newPassword"
                                        name="newPassword"
                                        placeholder="Enter new password"
                                        value={formData.newPassword}
                                        onChange={handleInputChange}
                                        autoComplete='off'
                                    />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <Button color="primary" className="btn-sm" type="submit">Change Password</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

        </>
    );
};

export default ProfileDropdown;