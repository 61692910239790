import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Input, ListGroup, ListGroupItem, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Spinner, TabPane, UncontrolledCollapse } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { downloadAllPCIEvidenceAPI, downloadEvidenceViewAPI, getCCFControlsApi, getPCIEvidenceAPI, updateApplicableChangeAPI } from '../../../apiConfig/apiCall';
import useUserDetails from '../../../hooks/useUserDetails';

export default function OtherComplianceData({ otherComplianceData, setOtherComplianceData, otherComplianceTabName, toggleEvidence, pid, activeFilter, setActiveFilter, originalOtherComplianceData, currentOtherComplianceNameShow, pcid }) {

    const { CURRENT_ROLE } = useUserDetails()

    const role = CURRENT_ROLE;

    // All States
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState(otherComplianceData);
    const [openDropdownForOtherControls, setOpenDropdownForOtherControls] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    // Handle Pagination Logic For CCF Start
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredCCFData = filteredData && filteredData.filter(data => {
        const searchQueryLower = searchQuery.toLowerCase();

        if (data.cvp) {
            if (data.cvp.certificationIndex && data.cvp.certificationIndex.toLowerCase().includes(searchQueryLower)) {
                return true;
            }
            if (data.cvp.controlWording && data.cvp.controlWording.toLowerCase().includes(searchQueryLower)) {
                return true;
            }
        }

        return false;
    });

    const paginateCCFData = filteredCCFData.slice(indexOfFirstItem, indexOfLastItem);


    const handleNextPage = () => {
        if (currentPage < Math.ceil(otherComplianceData.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    // Handle Pagination Logic For CCF End

    // Toggle Other Complaince Controls Start
    const toggleDropdownForOtherControls = (id) => {
        setOpenDropdownForOtherControls(openDropdownForOtherControls === id ? null : id);
    };
    // Toggle Other Complaince Controls End

    // Handle Filter CCF From Controls Start
    const [currentCCFFilterControlApply, setCurrentCCFFilterControlApply] = useState('');
    const handleFilterCCFFromControls = async (controlValue) => {

        try {
            setCurrentPage(1);
            setCurrentCCFFilterControlApply(controlValue.version);

            setTimeout(() => {

                const matchedCompliance = otherComplianceTabName.find(item => item.title === controlValue);

                if (matchedCompliance) {
                    setOtherComplianceData(originalOtherComplianceData);
                    setCurrentCCFFilterControlApply(`All ${matchedCompliance.title}`);

                } else {
                    const filteredCCF = originalOtherComplianceData.filter(data =>
                        Array.isArray(data.cvp?.ccfNameList) &&
                        data.cvp.ccfNameList.some(ccfItem =>
                            ccfItem.Department === controlValue.version ||
                            ccfItem.DomainTitle === controlValue.version ||
                            ccfItem.ControlType === controlValue.version
                        )
                    );
                    setFilteredData(filteredCCF);
                    setOtherComplianceData(filteredCCF);
                    setCurrentPage(1);

                }
            }, 500);
        } catch (error) {
            console.log(error);
        }
    };
    // Handle Filter CCF From Controls End


    // Search CCF Logic Start
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };
    // Search CCF Logic End

    // Get CCF Control Api Logic Start
    const [ccfControls, setCcfControls] = useState({});
    const getCCFControls = async () => {
        try {
            const apiResponse = await getCCFControlsApi();
            if (apiResponse.status === 200) {
                setCcfControls(apiResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
    // Get CCF Control Api Logic End
    const transformDataToProjects = (data) => {
        const bgColors = ['danger', 'info', 'warning', 'success'];
        return Object.keys(data).map((key, index) => ({
            id: index + 1,
            bg: bgColors[index % bgColors.length],
            subItem: data[key]
                .filter(item => item)
                .map((version, subIndex) => ({
                    iconClass: bgColors[index % bgColors.length],
                    id: subIndex + 1,
                    version: version
                })),
            title: key.replace(/([a-z])([A-Z])/g, '$1 $2')
        }));
    };

    const ccfControlsValue = transformDataToProjects(ccfControls);

    //  Filter CCF All Inscope Outscope Logic Start
    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
        setCurrentPage(1);
        if (filter === 'All') {
            setFilteredData(otherComplianceData);
        } else if (filter === 'In-Scope') {
            setFilteredData(otherComplianceData.filter(item => item.applicable === true));
        } else if (filter === 'Out-Scope') {
            setFilteredData(otherComplianceData.filter(item => item.applicable === false));
        }
    };
    //  Filter CCF All Inscope Outscope Logic End

    const TruncatedText = ({ text, wordLimit = 50 }) => {
        const words = text.split(' ');
        const truncatedText = words.slice(0, wordLimit).join(' ');
        const [isExpanded, setIsExpanded] = useState(false);

        return (
            <div className="text-muted job-description" style={{ fontSize: '.7rem', textAlign: 'justify' }}>
                {isExpanded ? text : `${truncatedText}${words.length > wordLimit ? '...' : ''}`}
                {words.length > wordLimit && (
                    <button onClick={() => setIsExpanded(!isExpanded)} style={{ border: 'none', background: 'none', color: '#007bff', cursor: 'pointer', padding: '0' }}>
                        {isExpanded ? 'Read Less' : 'Read More'}
                    </button>
                )}
            </div>
        );
    };

    // Applicable or not
    const handleCheckboxChange = async (checked, id) => {

        const data = {
            pcid,
            craid: id,
            applicable: checked,
        };

        const apiResponse = await updateApplicableChangeAPI(data);
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data);
        }
        const updatedData = otherComplianceData.map((data) =>
            data.id === id ? { ...data, applicable: checked } : data
        );
        setOtherComplianceData(updatedData);
    };

    // PCI Evidence Canvas
    const [evidenceFile, setEvidenceFile] = useState([]);
    const [pciEvidenceCanvas, setPciEvidenceCanvas] = useState(false);
    const [idForDownloadAllEvidence, setIdForDownloadAllEvidence] = useState();
    const [pciActivityName, setPciActivityName] = useState('');

    const togglePciEvidenceCanvas = () => {
        setPciEvidenceCanvas(!pciEvidenceCanvas);
    };

    const togglePciEvidence = async (CRA_ID, data) => {
        try {

            setPciActivityName(data.cvp.certificationType + "-" + data.cvp.certificationIndex)

            setIdForDownloadAllEvidence(CRA_ID)
            setPciEvidenceCanvas(true);
            const apiResponse = await getPCIEvidenceAPI(pid, CRA_ID);
            if (apiResponse.status === 200) {
                setEvidenceFile(apiResponse.data);
            } else {
                setEvidenceFile([]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const downloadEvidence = async (filename) => {
        try {
            const filePath = filename;
            const fileNameFormatDownload = filename.split(/[/\\]/).pop()
            const encodedFilePath = encodeURIComponent(filePath);
            const apiResponse = await downloadEvidenceViewAPI(pid, encodedFilePath, 'blob');
            if (apiResponse.status === 200) {
                const blob = new Blob([apiResponse.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileNameFormatDownload);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            }
        } catch (error) {
            console.log('Error while downloading file:', error);
        }
    };

    // getAllPCIEvidenceAPI
    const handleDownloadAllEvidence = async () => {
        try {
            const apiResponse = await downloadAllPCIEvidenceAPI(pid, idForDownloadAllEvidence, "blob");
            if (apiResponse.status === 200) {
                const blob = new Blob([apiResponse.data], { type: 'application/zip' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `evidences.zip`);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            }
        } catch (error) {
            console.log("Error while downloading file:", error);
        }
    };

    useEffect(() => {
        getCCFControls();
        if (otherComplianceData.length !== 0) {
            setFilteredData(otherComplianceData);
        }
    }, [otherComplianceData]);

    return (
        <>
            {
                otherComplianceTabName && otherComplianceTabName.map((item, index) => (
                    <TabPane tabId={item.title} key={index}>
                        <Row>
                            <Col xxl={2} xl={3} lg={3} className='pe-1'>
                                <Card>
                                    <CardBody className='projectTaskCCFDataControls p-2'>
                                        <SimpleBar className="px-4 mx-n4">
                                            <ul className="to-do-menu list-unstyled" id="projectlist-data">
                                                <li>
                                                    <Link
                                                        to="#"
                                                        className="nav-link fs-11 d-flex align-items-center justify-content-between"
                                                        onClick={() => handleFilterCCFFromControls(item.title)}>
                                                        <div className='d-flex'>
                                                            <span className={"icon me-2 bg-" + 'danger' + "-subtle" + " text-" + 'danger'}>
                                                                <i className='bx bxs-compass'></i>
                                                            </span>
                                                            All Control
                                                        </div>
                                                        <i className='ri-arrow-right-s-line'></i>
                                                    </Link>
                                                </li>
                                                {ccfControlsValue
                                                    .filter((item) => item.title !== "Control Title")
                                                    .map((item) => (
                                                        <li key={item.id}>
                                                            <Link
                                                                to="#"
                                                                className="nav-link fs-11 d-flex align-items-center"
                                                                id={"todos" + item.id}
                                                                onClick={() => toggleDropdownForOtherControls(item.id)}
                                                            >
                                                                <div className='d-flex justify-content-between w-100'>
                                                                    <div className='d-flex'>
                                                                        <span className={"icon me-2 bg-" + item.bg + "-subtle" + " text-" + item.bg}>
                                                                            <i className='bx bxs-compass'></i>
                                                                        </span>
                                                                        {item.title}
                                                                    </div>
                                                                    <i className={openDropdownForOtherControls === item.id ? "ri-arrow-down-s-line" : "ri-arrow-right-s-line"}></i>
                                                                </div>
                                                            </Link>
                                                            <UncontrolledCollapse isOpen={openDropdownForOtherControls === item.id} toggler={"#todos" + item.id}>
                                                                <ul className="mb-0 sub-menu control-list list-unstyled list-unstyled ps-1 vstack gap-2 mb-2">
                                                                    {item.subItem.map((subItem) => (
                                                                        <li key={subItem.id}>
                                                                            <Link
                                                                                to="#"
                                                                                style={{ fontSize: '.7rem', display: 'flex', position: 'relative' }}
                                                                                onClick={() => handleFilterCCFFromControls(subItem, item.title)}
                                                                            >
                                                                                <i className={"ri-stop-mini-fill align-middle fs-10 me-1 text-" + subItem.iconClass} style={{ position: 'absolute', top: '0.05rem' }}></i>
                                                                                <div className='ps-3'>{subItem.version}</div>
                                                                            </Link>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </UncontrolledCollapse>
                                                        </li>
                                                    ))}


                                            </ul>
                                        </SimpleBar>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xxl={10} xl={9} lg={9} className='ps-1 pe-1'>
                                <Card>

                                    <CardBody>

                                        <CardHeader className='p-0 pb-2 d-flex align-items-start'>
                                            <div className="filters d-flex">
                                                <div
                                                    className={`filter d-flex me-2 ${activeFilter === 'All' ? 'active' : ''}`}
                                                    onClick={() => handleFilterClick('All')}
                                                >
                                                    <div className="icon me-2">
                                                        <i className="ri-sound-module-line"></i>
                                                    </div>
                                                    All
                                                </div>
                                                <div
                                                    className={`filter d-flex me-3 ${activeFilter === 'In-Scope' ? 'active' : ''}`}
                                                    onClick={() => handleFilterClick('In-Scope')}
                                                >
                                                    <div className="icon me-2">
                                                        <i className="ri-sound-module-line"></i>
                                                    </div>
                                                    In-Scope
                                                </div>
                                                <div
                                                    className={`filter d-flex ${activeFilter === 'Out-Scope' ? 'active' : ''}`}
                                                    onClick={() => handleFilterClick('Out-Scope')}
                                                >
                                                    <div className="icon me-2">
                                                        <i className="ri-sound-module-line"></i>
                                                    </div>
                                                    Out-Scope
                                                </div>
                                            </div>

                                            <div className="search app-search p-0 ms-3">
                                                <div className="position-relative">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                    />
                                                    <span className="mdi mdi-magnify search-widget-icon"></span>
                                                </div>
                                            </div>
                                        </CardHeader>

                                        <div className='projectTaskCCFData'>

                                            {loading ? (
                                                <div className="text-center" style={{ height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <div>
                                                        <Spinner color="primary" style={{ opacity: '.2' }} />
                                                        <p style={{ opacity: '.4' }}>Loading Data...</p>
                                                    </div>
                                                </div>

                                            ) : (
                                                (paginateCCFData && paginateCCFData.length > 0) ? (
                                                    paginateCCFData.map((data, key) => (
                                                        <div key={key} className='py-2 mb-2' style={{ borderBottom: '2px solid #e9ebec' }}>
                                                            <div className="d-flex mb-1">
                                                                <div className="avatar-xxs">
                                                                    <div className="avatar-xxs bg-danger-subtle rounded d-flex justify-content-center align-items-center">
                                                                        <i className="bx bxs-compass text-danger"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 flex-grow-1">
                                                                    <div className='d-flex align-items-center'>

                                                                        <h5 className="job-title fs-12 mb-0 me-3" style={{
                                                                            cursor: 'pointer', textDecoration: 'underline'
                                                                        }}
                                                                            onClick={() => togglePciEvidence(data.id, data)}
                                                                        >{data.cvp.certificationIndex}</h5>

                                                                        {role === "AUDITOR" && (
                                                                            <input
                                                                                type="checkbox"
                                                                                className='me-2'
                                                                                checked={data.applicable || false}
                                                                                onChange={(e) => handleCheckboxChange(e.target.checked, data.id)}
                                                                            />
                                                                        )}
                                                                        <TruncatedText text={data.cvp.controlWording} wordLimit={30} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-center flex-wrap mb-1'>
                                                                {
                                                                    data.cvp.ccfNameList.map((item, key) => (
                                                                        <span
                                                                            className={`me-3 mb-1 cursor-pointer badge ${item.status === "PROCESSING"
                                                                                ? "bg-warning-subtle text-warning"
                                                                                : item.status === "PENDING"
                                                                                    ? "bg-info-subtle text-info"
                                                                                    : item.status === "COMPLETED"
                                                                                        ? "bg-success-subtle text-success"
                                                                                        : item.status === "REJECTED"
                                                                                            ? "bg-danger-subtle text-danger"
                                                                                            : "bg-light-subtle text-dark"
                                                                                }`}
                                                                            key={key}
                                                                            onClick={() => toggleEvidence(item.id, pid, item.ccf)}
                                                                        >
                                                                            <i className="ri-honour-line align-bottom me-1"></i>
                                                                            {item.ccf}
                                                                        </span>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="text-center py-3">
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                )

                                            )}

                                        </div>

                                    </CardBody>

                                    <div className='d-flex justify-content-between align-items-center' style={{ borderTop: '1px solid #ccc', padding: '.5rem' }}>
                                        <p className="mb-0 ps-3">Current control is : <span className="fw-bold">{currentCCFFilterControlApply === "" ? "All Control" : currentCCFFilterControlApply}</span></p>
                                        {/* Pagination controls */}
                                        <div className="pagination-controls">
                                            <Button color='primary' size='sm' onClick={handlePreviousPage} disabled={currentPage === 1}>
                                                Previous
                                            </Button>
                                            <span> Page {currentPage} of {Math.max(1, Math.ceil(filteredData.length / itemsPerPage))} </span>
                                            <Button color='primary' size='sm' onClick={handleNextPage} disabled={currentPage >= Math.ceil(filteredData.length / itemsPerPage)}>
                                                Next
                                            </Button>
                                        </div>
                                        <p className="mb-0 pe-3">Showing <span className="fw-bold">{filteredData.length}</span> {currentOtherComplianceNameShow} Points</p>
                                    </div>

                                </Card>
                            </Col>
                        </Row>
                    </TabPane >
                ))
            }

            <Offcanvas direction="end" isOpen={pciEvidenceCanvas} toggle={togglePciEvidenceCanvas} id="offcanvasExample">
                <OffcanvasHeader toggle={togglePciEvidenceCanvas} id="offcanvasExampleLabel" className="border-bottom py-2" >
                    <div className="fs-13 mb-0">{pciActivityName} Activity</div>
                </OffcanvasHeader>
                <OffcanvasBody className="hideScrollbar">

                    <h6>Evidence</h6>

                    <div
                        className="d-flex align-items-center mb-2 cursor-pointer"
                        onClick={evidenceFile.length > 0 ? handleDownloadAllEvidence : null}
                        style={{ cursor: evidenceFile.length > 0 ? 'pointer' : 'default' }}
                    >
                        <i className={`ri-${evidenceFile.length > 0 ? 'download-line' : 'alert-line'} text-dark me-2`} style={{ fontSize: '16px' }}></i>
                        <p
                            className="mb-0"
                            style={{
                                textDecoration: evidenceFile.length > 0 ? 'underline' : 'none',
                                color: evidenceFile.length > 0 ? '#000' : '#888',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}
                        >
                            {evidenceFile.length > 0 ? 'Download all evidence' : 'No evidence to show'}
                        </p>
                    </div>



                    <ListGroup className='mt-4'>
                        {evidenceFile.map((file, index) => (
                            <ListGroupItem className='mb-2 border d-flex justify-content-between py-2' key={index}>
                                <div className="d-flex align-items-center">
                                    <i className="ri-bill-line align-middle text-muted fs-5 me-3"></i>
                                    <div>
                                        <p className="mb-0 fs-12 text-muted">
                                            {(file.file).split(/[/\\]/).pop().slice(-20)}
                                        </p>
                                        <h5 className='fs-11 mb-0'>{file.firstName}</h5>
                                    </div>
                                </div>
                                <div className="evd-action d-flex flex-column">
                                    <i className="ri-download-2-line cursor-pointer" onClick={() => downloadEvidence(file.file)}></i>
                                </div>
                            </ListGroupItem>
                        ))}

                    </ListGroup>

                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}