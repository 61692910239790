import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { ReactTable } from './ReactTable';
import BreadCrumb from '../../Components/Common/BreadCrumb'
import { getAssetsApi } from '../../apiConfig/apiCall';
import DataLoading from '../../Components/Common/DataLoading';

const AssetsList = () => {

  const [loading, setLoading] = useState(true);

  // Get Assets List Logic
  const [assetListData, setAssetListData] = useState([]);
  const gteAssets = async () => {
    try {
      const apiResponse = await getAssetsApi();
      if (apiResponse.status === 200) {
        setAssetListData(apiResponse.data);
      }
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      await gteAssets();
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <DataLoading />
      ) : (
        <div className="page-content asset-page">
          <Container fluid>
            <BreadCrumb title={'Assets List'} pageTitle={'Dashboard'} />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Assets List</h5>
                  </CardHeader>
                  <CardBody className='pt-0'>
                    <ReactTable assetListData={assetListData} gteAssets={gteAssets} />
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      )}
    </>
  )
}

export default AssetsList;